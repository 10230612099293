import {
  BooleanField,
  Create,
  Datagrid,
  Edit,
  EditButton,
  List,
  NumberField,
  Pagination,
  ReferenceManyField,
  Show,
  ShowButton,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
} from 'react-admin';

import React from 'react';

export const TenantEdit = (props: any) => {
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm redirect="list">
        <TextInput source="name" type="text" fullWidth />
        <TextInput
          source="emailFromAddress"
          label="Email Sender Address"
          helperText="Format: <email>, <name> | Example: test@tester.com, Test Tester"
          fullWidth
        />
        <TextInput
          source="emailTemplate"
          label="SendGrid Email Template ID"
          helperText="Default viRace Template is used if empty"
          fullWidth
        />
      </SimpleForm>
    </Edit>
  );
};

export const TenantList = (props: any) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export const TenantShow = (props: any) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Info">
          <TextField source="id" />
          <TextField source="name" type="text" fullWidth />
        </Tab>
        <Tab label="Users">
          <ReferenceManyField pagination={<Pagination />} perPage={25} label="Users" reference="user" target="tenantId" source="id">
            <Datagrid>
              <NumberField source="id" />
              <TextField source="username" />
              <TextField source="firstname" />
              <TextField source="lastname" />
              <BooleanField source="isTester" />
              <BooleanField source="isAdmin" />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const TenantCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" type="text" fullWidth />
      <TextInput
        source="emailFromAddress"
        label="Email Sender Address"
        helperText="Format: <email>, <name> | Example: test@tester.com, Test Tester"
        fullWidth
      />
    </SimpleForm>
  </Create>
);
