import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  ShowButton,
  EditButton,
  Filter,
  required,
  SimpleFormIterator,
  Show,
  ArrayInput,
  DateField,
  TabbedShowLayout,
  Tab,
  SelectInput,
  NumberInput,
} from 'react-admin';
import { LOCALES } from '../../constants/locales';
import { ATTRACTIONTYPES } from './attractions';

const AttractionDefaultFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Name" source="name||$contL" alwaysOn />
  </Filter>
);

export const AttractionDefaultList = (props: any) => (
  <List {...props} filters={<AttractionDefaultFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="default" />
      <DateField source="createdAt" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export const AttractionDefaultCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" validate={[required()]} />
      <ArrayInput source="default" validate={[required()]}>
        <SimpleFormIterator>
          <NumberInput
            label="Distance IN METERS or seconds depending on type"
            source="distance"
            fullWidth
            validate={[required()]}
          />
          <TextInput
            label="String to say"
            multiline
            source="speakString"
            fullWidth
          />
          <SelectInput
            source="language"
            label="Language"
            choices={LOCALES}
            validate={[required()]}
          />
          <SelectInput
            source="type"
            label="Type"
            choices={ATTRACTIONTYPES}
            validate={[required()]}
          />
          <TextInput
            source="sound"
            label="Add Sound only .mp3 works! Add Link of a alread uploaded file!"
            multiline
            fullWidth
          />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);

const AttractionDefaultEditTitle = ({ record }: any) => (
  <span>{`${record.name}`}</span>
);

export const AttractionDefaultEdit = (props: any) => (
  <Edit {...props} title={<AttractionDefaultEditTitle record />}>
    <SimpleForm redirect="list">
      <TextInput source="name" validate={[required()]} />
      <ArrayInput source="default" validate={[required()]}>
        <SimpleFormIterator>
          <NumberInput
            label="Distance IN METERS or seconds depending on type"
            source="distance"
            fullWidth
            validate={[required()]}
          />
          <TextInput
            label="String to say"
            multiline
            source="speakString"
            fullWidth
          />
          <SelectInput
            source="language"
            label="Language"
            choices={LOCALES}
            validate={[required()]}
          />
          <SelectInput
            source="type"
            label="Type"
            choices={ATTRACTIONTYPES}
            validate={[required()]}
          />
          <TextInput
            source="sound"
            label="Add Sound only .mp3 works! Add Link of a alread uploaded file!"
            multiline
            fullWidth
          />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

export const AttractionDefaultShow = (props: any) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Default">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="default" />
        <DateField source="createdAt" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
