import React, { cloneElement } from 'react';
import {
  Create,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  ExportButton,
  Filter,
  List,
  NumberField,
  Pagination,
  ReferenceManyField,
  required,
  sanitizeListRestProps,
  Show,
  ShowButton,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
} from 'react-admin';
import { Form } from 'react-final-form';

import { Box, Button, Card, CardContent, CssBaseline, Grid } from '@material-ui/core';

import { APIService } from '../../common/api.service';

const onRecalculateRanking = async (value: any) => {
  await APIService.calculateRanking(value.id);
};

const RankingsFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Id" source="id||$eq" alwaysOn />
    <TextInput label="Name" source="name||$contL" alwaysOn />
  </Filter>
);

export const RankingsList = (props: any) => (
  <List {...props} filters={<RankingsFilter />} actions={<ListActions />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

const ListActions = (props: any) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { currentSort, resource, displayedFilters, filterValues, basePath, showFilter, total } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton disabled={total === 0} resource={resource} sort={currentSort} filterValues={filterValues} maxResults={10000} />
    </TopToolbar>
  );
};
const CommonFormPart = (props: any) => (
  <div style={{ width: '100%' }}>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Card>
          <CardContent>
            <span>
              <TextInput source="name" validate={[required()]} fullWidth />
              <DateInput source="startDate" validate={[required()]} fullWidth />
              <DateInput source="endDate" validate={[required()]} fullWidth />
            </span>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </div>
);

export const RankingsCreate = (props: any) => {
  return (
    <Create {...props}>
      <SimpleForm redirect="show">
        <CommonFormPart {...props} />
      </SimpleForm>
    </Create>
  );
};

const RankingsEditTitle = ({ record }: any) => <span>{`${record.id} | ${record.name}`}</span>;

export const RankingsEdit = (props: any) => (
  <Edit {...props} title={<RankingsEditTitle record />}>
    <SimpleForm redirect="list">
      <CommonFormPart {...props} />
    </SimpleForm>
  </Edit>
);

const RankingShowActions = (props: any) => {
  return (
    <TopToolbar>
      <EditButton basePath={props.basePath} record={props.data} />
      {/* Add your custom actions */}
    </TopToolbar>
  );
};

export const RankingsShow = (props: any) => {
  // const classes = useStyles();
  return (
    <Show actions={<RankingShowActions {...props} />} {...props}>
      <TabbedShowLayout>
        <Tab label="General">
          <TextField source="id" />
          <TextField source="name" />
          <DateField source="startDate" />
          <DateField source="endDate" />
          <DateField source="createdAt" />
          <DateField source="updatedAt" />
        </Tab>
        <Tab label="Items">
          <ReferenceManyField
            pagination={<Pagination />}
            perPage={100}
            label="Item"
            reference="ranking-item"
            target="rankingId"
            source="id"
          >
            <Datagrid>
              <NumberField source="id" />
              <NumberField source="userId" />
              <NumberField source="result" />
              <NumberField source="rank" />
              <DateField source="createdAt" />
              <DateField source="updatedAt" />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Races">
          <ReferenceManyField pagination={<Pagination />} perPage={100} label="Races" reference="room" target="rankingId" source="id">
            <Datagrid>
              <NumberField source="id" />
              <TextField source="event_title" />
              <DateField source="startDate" />
              <DateField source="endDate" />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        {props.permissions === 'superadmin' && (
          <Tab label="Actions">
            <div>
              <CssBaseline />
              <Form
                onSubmit={onRecalculateRanking}
                initialValues={{ id: props.id }}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <Box my={2}>
                      <Button variant="contained" color="primary" type="submit" disabled={submitting}>
                        Recalculate Ranking
                      </Button>
                    </Box>
                  </form>
                )}
              />
            </div>
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};
