import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport';

const restrictedUsersExporter = (users: any) => {
  const usersForExport = users.map((user: any) => {
    const {
      externalId,
      tenantId,
      state,
      runningCoachId,
      subscribeMigrosEmail,
      deviceToken,
      installedVersion,
      os,
      manufacturer,
      sport,
      source,
      status,
      tshirt_size,
      team,
      tenant,
      ...userForExport
    } = user; // Remove attributes here
    return userForExport;
  });

  jsonExport(usersForExport, (err: any, csv: any) => {
    downloadCSV(csv, 'users'); // download as 'users.csv` file
  });
};

export default restrictedUsersExporter;
