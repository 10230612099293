import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport';

const badgeUsersExporter = (badgeUsers: any) => {
  const codesToExport = badgeUsers.map((bu: any) => {
    return {
      id: bu.id,
      badgeId: bu.badgeId,
      badgeTitle: bu.badge.title,
      userId: bu.userId,
      'user.username': bu.user.username,
      'user.firstname': bu.user.firstname,
      'user.lastname': bu.user.lastname,
      'user.email': bu.user.email,
      'user.street': bu.user.street,
      'user.address2': bu.user.address2,
      'user.city': bu.user.city,
      'user.zip': bu.user.zip,
      'user.country': bu.user.country,
      'user.gender': bu.user.gender,
      'user.tshirt_size': bu.user.tshirt_size,
      createdAt: bu.createdAt,
      updatedAt: bu.updatedAt,
    };
  });

  jsonExport(codesToExport, (err: any, csv: any) => {
    downloadCSV(csv, `${Date.now()}_badge-users`);
  });
};

export default badgeUsersExporter;
