import React, { useState } from 'react';

import { Card } from '@material-ui/core';
import { SimpleForm, FileField, FileInput } from 'react-admin';
import { useToasts } from 'react-toast-notifications';
import { APIService } from '../../common/api.service';

const CSV_ACCEPT = '.csv';

export const ImportCSV = () => {
  const resource = 'user';
  const source = 'userImport';
  const { addToast } = useToasts();
  const [result, setResult] = useState<object>();

  const onDropCallback = async (files: File[]) => {
    files.forEach(async (file) => {
      const apiResult = await APIService.uploadFile(file, resource, source);

      if (apiResult) {
        setResult(apiResult);
        addToast('Import successful!', {
          appearance: 'success',
          autoDismiss: true,
        });
      } else {
        addToast('Something went wrong!', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    });
  };

  return (
    <Card style={{ marginTop: 10 }}>
      <SimpleForm toolbar={false}>
        <FileInput source={source} label="CSV User Import" accept={CSV_ACCEPT} options={{ onDrop: onDropCallback }}>
          <FileField source="url" title="url" />
        </FileInput>
        {result ? (
          <Card raised={true} style={{ width: '100%' }}>
            <pre>{JSON.stringify(result, null, 2)}</pre>
          </Card>
        ) : null}
      </SimpleForm>
    </Card>
  );
};
