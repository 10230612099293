import React, { isValidElement, cloneElement } from 'react';

import { ArrayInput, SimpleFormIterator, SelectInput, required } from 'react-admin';
import { LOCALES } from '../constants/locales';

interface Props {
  source: string;
  label?: string;
  helperText?: string;
}

export class Translatable extends React.Component<Props> {
  static defaultProps: Props = {
    source: '',
  };

  render() {
    const { source, label } = this.props;
    const translatedSource = source + 'Translations';

    const childrenWithSource = React.Children.map(this.props.children, (child) => {
      if (isValidElement(child)) {
        return cloneElement(child, {
          source: 'value',
          helperText: this.props.helperText,
        });
      }
    });

    return (
      <ArrayInput source={translatedSource} label={label ? label : source + ' Translations'}>
        <SimpleFormIterator>
          <SelectInput label="Locale" choices={LOCALES} source="locale" validate={required()} fullWidth />
          {childrenWithSource}
        </SimpleFormIterator>
      </ArrayInput>
    );
  }
}
