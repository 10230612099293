export const ATTRACTION_DOCUMENTATION = `### Documentation
\n If there is AT LEAST ONE custom attraction here (in the specific language) -> all auto generated attractions will be discared on the device.
\n One can generate an attraction set from a default set but only if there is no existing custom attraction for this event.
\n There will be no countdown in the race (has to be added manually). Migros string regarding price are still managed from the app.
\n ### Language Fallback for Sound Attractions
\n If AT LEAST ONE sound attraction for a specific language exists, then no automatic language fallback is used.
\n To easily generate manual fallbacks of existing sound files, one can use the "Generate Language Fallback" tool at the bottom of this page.
`;

export const ATTRACTION_TIMING = `### Documentation \n #### ... AT THE START or ... FINISH \n just put in 0 into the distance field.
\n #### string BEFORE or sound BEFORE \n seconds before start into distance field. (positive or negative work from now on forward)
\n Example: 2:30min before the start would be 150.
\n #### string AFTER, sound AFTER \n seconds after finsish into distance field.
\n #### string DURING, sound DURING \n put in the distance in meters.`;

export const ATTRACTION_KEYWORDS = `### Keywords:
\n EVENT_TITLE: Name of the Event
\n AMOUNT_OF_RUNNERS: Amount of runners racing
\n CURRENT_TIME: Current running time from Start
\n CURRENT_POSITION_OVERALL: Current Rank during race
\n OVERALL_PLACEMENT: Finished Ranking. Used after Finish
\n FINAL_TIME: Time at the finish
\n DISTANCE_LEFT_UNIT: Distance to go to the finish
\n &nbsp;
\n BIG_FAV_RANKING: normally said on full kilometer/mile
\n SMALL_FAV_RANKING: normally said on half kilometer/mile
\n ONLY_FAVORITES: normally said on 1.75,3.75,5.75... kilometer/mile
\n FINISH_FAVORITES: favorits in the finish
`;
