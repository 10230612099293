import {
  BooleanField,
  BooleanInput,
  Create,
  DateField,
  Edit,
  ReferenceInput,
  SelectInput,
  Show,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  required,
} from 'react-admin';
import { Card, CardContent, Grid } from '@material-ui/core';

import React from 'react';
import { Translatable } from '../../common/translatable';
import { parse } from 'query-string';

const ReactMarkdown = require('react-markdown');

const documentation = `
  ## Attributes Documentation
  ### Required
  true = User can choose whether to pay for this
  false = User cannot choose and has to pay for this

  ### Type
  participation = Use this to make a signup after successful payment\n
  donation = Add a input field to the checkout which can be used to add arbitrary amounts

  ### Name Translation
  Provide translated names for the checkout form.

  ### Attributes
  Give arbitrary data to this PaymentItem. The following attributes are needed, depending on the type:

  Participation : {"roomId": "<roomId>"}

  ### Prizes
  Provide the prize of this PaymentItem in different currencies.\n
  **Important:** All PaymentItems of a Payment should have the same set of currencies available.
`;

const CommonFormPart = (props: any) => (
  <div style={{ width: '100%' }}>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Card>
          <CardContent>
            <span>
              <ReferenceInput
                perPage={1000}
                sort={{ field: 'id', order: 'DESC' }}
                label="Payment"
                source="paymentId"
                reference="payment"
                fullWidth
              >
                <SelectInput optionText={(record: any) => record.id + ' | ' + record.name} fullWidth />
              </ReferenceInput>
              <TextInput source="name" validate={[required()]} fullWidth />
              <BooleanInput source="required" helperText="Select if user should not be able to deselect this item in the checkout" />
              <SelectInput
                source="type"
                label="Type"
                choices={[
                  { id: 'participation', name: 'Participation' },
                  { id: 'item', name: 'Item' },
                  { id: 'donation', name: 'Donation' },
                ]}
                validate={[required()]}
                fullWidth
              />
              <Translatable source="name">
                <TextInput label="Name" fullWidth />
              </Translatable>
              <TextInput
                source="attributes"
                format={(v: any) => JSON.stringify(v)}
                parse={(v: any) => JSON.parse(v)}
                helperText='Examples: {"roomId": "1234"} , {"color": "Blue, Red, Yellow", "size": "S, M, L"}'
                validate={[required()]}
                fullWidth
              />
              <TextInput
                source="prizes"
                format={(v: any) => JSON.stringify(v)}
                parse={(v: any) => JSON.parse(v)}
                helperText='Prizes in Cents. Important: Make sure that the same currencies are available for all PaymentItems of a specific Payment! Example: {"CHF": 1000, "USD": 1000, "EUR": 900}'
                validate={[required()]}
                fullWidth
              />
            </span>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Card>
          <CardContent>
            <ReactMarkdown source={documentation} fullWidth></ReactMarkdown>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </div>
);

export const PaymentItemsCreate = (props: any) => {
  // Read the post_id from the location which is injected by React Router and passed to our component by react-admin automatically
  const { paymentId: paymentId_string } = parse(props.location.search);
  const paymentId = paymentId_string ? parseInt(paymentId_string as string, 10) : '';
  return (
    <Create {...props}>
      <SimpleForm defaultValue={{ paymentId }} redirect="show">
        <CommonFormPart {...props} />
      </SimpleForm>
    </Create>
  );
};

const PaymentItemEditTitle = ({ record }: any) => <span>{`${record.id} | ${record.name}`}</span>;

export const PaymentItemsEdit = (props: any) => (
  <Edit {...props} title={<PaymentItemEditTitle record />}>
    <SimpleForm redirect="show">
      <CommonFormPart {...props} />
    </SimpleForm>
  </Edit>
);

export const PaymentItemsShow = (props: any) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="General">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="type" />
        <BooleanField source="required" />
        <TextField source="nameTranslations" />
        <TextField source="attributes" />
        <TextField source="prizes" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
