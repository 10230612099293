import { DateField, Show, Tab, TabbedShowLayout, TextField } from 'react-admin';

import React from 'react';

export const RankingItemsShow = (props: any) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="General">
        <TextField source="id" />
        <TextField source="userId" />
        <TextField source="rank" />
        <TextField source="details" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
