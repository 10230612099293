import jsonExport from 'jsonexport';
import React from 'react';
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  downloadCSV,
  Edit,
  EditButton,
  Filter,
  List,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from 'react-admin';

import { getPrizeTitle } from '../prizes/prizes';
import { FormattedUserField, getFormattedUser } from '../users/users';

// Custom exporter to fix unusable csv export
const exporter = (records: any, _fetchRelatedRecords: any) => {
  const prizeWinnersForExport = records.map((prizeWinner: any) => {
    // Remove all joined room information since the translations
    // fuck up the format
    delete prizeWinner['prize']['room'];
    return prizeWinner;
  });

  jsonExport(prizeWinnersForExport, (err: any, csv: any) => {
    downloadCSV(csv, 'prize-winners'); // download as 'prize-winners.csv` file
  });
};

const PrizeWinnerFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Prize" source="winner.prize.title" alwaysOn />
    <TextInput label="Race ID" source="prize.room.id||$eq" alwaysOn />
    <TextInput label="User" source="winner.user.username" />
    <TextInput label="Race Title" source="prize.room.event_title" />
  </Filter>
);

export const PrizeWinnerList = (props: any) => (
  <List {...props} filters={<PrizeWinnerFilter />} exporter={exporter}>
    <Datagrid rowClick="edit">
      <TextField source="prize.room.event_title" />
      <TextField source="prize.title" />
      <FormattedUserField source="user" />
      <BooleanField source="collected" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

const PrizeWinnerEditTitle = ({ record }: any) => (
  <span>{`${record.prize.room.event_title}->${getPrizeTitle(record.prize.title)}:${getFormattedUser(record.user)}`}</span>
);

export const PrizeWinnerEdit = (props: any) => (
  <Edit {...props} title={<PrizeWinnerEditTitle record />}>
    <SimpleForm redirect="list">
      <TextField source="prize.room.event_title" />
      <TextField source="prize.title" />
      <FormattedUserField source="user" />
      <BooleanInput source="collected" />
      <TextInput fullWidth source="street" />
      <TextInput fullWidth source="address2" />
      <TextInput fullWidth source="zip" />
      <TextInput fullWidth source="city" />
      <TextInput fullWidth source="state" />
    </SimpleForm>
  </Edit>
);

export const PrizeWinnerShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="prize.room.event_title" />
      <TextField source="prize.title" />
      <FormattedUserField source="user" />
      <BooleanField source="collected" />
      <TextField source="street" />
      <TextField source="address2" />
      <TextField source="zip" />
      <TextField source="city" />
      <TextField source="state" />
    </SimpleShowLayout>
  </Show>
);
