import {
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  EditButton,
  ExportButton,
  Filter,
  List,
  Show,
  ShowButton,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  sanitizeListRestProps,
  useListContext,
} from 'react-admin';
import { ImportButton, ImportConfig } from 'react-admin-import-csv';

import React from 'react';

const DatalistEditTitle = ({ record }: any) => <span>{`${record.id} ${record.name}`}</span>;

export const DatalistEdit = (props: any) => (
  <Edit {...props} title={<DatalistEditTitle record />}>
    <SimpleForm redirect="list">
      <TextInput source="name" />
      <TextField source="data" />
      <DateTimeInput source="usedAt" />
      <TextInput source="reference" />
    </SimpleForm>
  </Edit>
);

const parseData = (data: any) => {
  let parsed = null;
  try {
    parsed = JSON.parse(data);
  } catch (error) {
    // Failed to parse JSON, probably no JSON then
    parsed = data;
  }
  return parsed;
};

// name and data are mandatory
const validateItem = (item: any) => {
  return item['name'] && item['data'] && item['name'].length;
};

const config: ImportConfig = {
  logging: true,
  validateRow: (item: any) => (validateItem(item) ? Promise.resolve() : Promise.reject()),
  transformRows: (items: any[]) => Promise.resolve(items.map((item) => ({ ...item, data: parseData(item.data) }))),
};

const ListFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Name" source="name||$contL" alwaysOn />
    <DateTimeInput label="Used At >" source="usedAt||$gt" alwaysOn />
    <TextInput label="Reference" source="reference||$contL" alwaysOn />
  </Filter>
);

const ListActions = (props: any) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { currentSort, resource, filterValues, total } = useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <ExportButton disabled={total === 0} resource={resource} sort={currentSort} filterValues={filterValues} maxResults={10000} />
      <ImportButton {...props} {...config} />
    </TopToolbar>
  );
};

export const DatalistList = (props: any) => (
  <List {...props} filters={<ListFilter />} actions={<ListActions />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="data" />
      <DateField source="usedAt" />
      <TextField source="reference" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export const DatalistShow = (props: any) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Datalist">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="data" />
        <TextField source="usedAt" />
        <TextField source="reference" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
