import { Card, CardHeader } from '@material-ui/core';
import React, { useCallback } from 'react';
import {
  SimpleForm,
  required,
  ReferenceInput,
  TextInput,
  SelectInput,
  BooleanInput,
  Toolbar,
  SaveButton,
  useRedirect,
  useNotify,
} from 'react-admin';
import { APIService } from '../../common/api.service';

const SaveWithNoteButton = (props: any) => {
  const redirectTo = useRedirect();
  const notify = useNotify();
  const { basePath } = props;
  const handleSave = useCallback(
    async (values, redirect) => {
      console.log('safe', values, redirect, basePath);
      const body = {
        attractions: {
          roomId: parseInt(values.roomId, 10),
          hasHalfKmOrMile: !!values.hasHalfKmOrMile,
          hasKmOrMile: !!values.hasKmOrMile,
          isMile: !!values.isMile,
          attractionDefaultId: values.attractionDefault,
        },
      };
      console.log('body', body);
      try {
        const attractions = await APIService.createAttractions(body);
        console.log('attractions', attractions);
        notify('Entries Generated', 'info', {
          smart_count: 1,
        });
        redirectTo(redirect, basePath, values.id, values);
      } catch (e) {
        console.log('error', e);
        notify('error generating ' + e, 'error', {
          smart_count: 1,
        });
        redirectTo(redirect, basePath, values.id, values);
      }
    },
    [notify, redirectTo, basePath]
  );
  // set onSave props instead of handleSubmitWithRedirect
  return <SaveButton label="Generate Attractions" {...props} onSave={handleSave} />;
};

const GenerateToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveWithNoteButton />
  </Toolbar>
);

const AttractionGenerateDefaults = (props: any) => {
  console.log(props);
  return (
    <Card>
      <CardHeader title="Generate from Defaults" />
      <SimpleForm toolbar={<GenerateToolbar />}>
        <TextInput style={{ display: 'none' }} source="roomId" initialValue={props.id} />
        <ReferenceInput
          perPage={200}
          sort={{ field: 'id', order: 'DESC' }}
          label="Defaults"
          source="attractionDefault"
          reference="attractionDefault"
          validate={[required()]}
          fullWidth
        >
          <SelectInput optionText={(item: any) => item.id + ' | ' + item.name} fullWidth />
        </ReferenceInput>
        <BooleanInput source="hasHalfKmOrMile" />
        <BooleanInput source="hasKmOrMile" />
        <BooleanInput source="isMile" />
      </SimpleForm>
    </Card>
  );
};

export default AttractionGenerateDefaults;
