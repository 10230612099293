import React from 'react';
import { Button, useRefresh } from 'react-admin';
import { useToasts } from 'react-toast-notifications';

import { withStyles } from '@material-ui/core/styles';
import ToggleIcon from '@material-ui/icons/ToggleOn';

import { APIService } from '../../common/api.service';

const styles = {
  button: {
    marginTop: '1em',
    marginBottom: '1em',
    marginRight: '1em',
  },
};

const availableToggles = ['ENABLE_MATCHING_QWG', 'ENABLE_AUTO_PARTICIPATION'];

const ToggleAdditionalDataButtons = ({ classes, record }: any) => {
  const { addToast } = useToasts();
  const refresh = useRefresh();
  const handleClick = async (name: string) => {
    const result = await APIService.toggleAdditionalData(record.id, name);
    if (result) {
      refresh();
      addToast('Reloaded!', {
        appearance: 'success',
        autoDismiss: true,
      });
    } else {
      addToast('Something went wrong!', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };
  return (
    <>
      {availableToggles.map((name: string) => (
        <Button key={name} className={classes.button} variant="contained" onClick={() => handleClick(name)} label={name}>
          <ToggleIcon />
        </Button>
      ))}
    </>
  );
};

export default withStyles(styles)(ToggleAdditionalDataButtons);
