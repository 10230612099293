import React from 'react';
import Button from '@material-ui/core/Button';
import { APIService } from '../../common/api.service';
import { useToasts } from 'react-toast-notifications';

const extractFilename = (response: any, fallback: string) => {
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const contentDisposition: string = response?.headers?.get('content-disposition');
  if (!contentDisposition) {
    return fallback;
  }

  const matches = filenameRegex.exec(contentDisposition);
  if (matches != null && matches[1]) {
    return matches[1].replace(/['"]/g, '');
  } else {
    return fallback;
  }
};

const DownloadProgressButton = (props: any) => {
  const { addToast } = useToasts();
  const handleClick = async () => {
    const response = await APIService.downloadParticipationProgress(props.record.id);
    if (response) {
      const content = await response.blob();
      const url = window.URL.createObjectURL(content);
      const a = document.createElement('a');
      a.href = url;
      a.download = extractFilename(response, `progress_${props.record.id}.json`);
      a.click();
    } else {
      addToast('File not found!', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  return (
    <Button variant="contained" size="small" color="primary" onClick={handleClick}>
      Download Progress
    </Button>
  );
};

export default DownloadProgressButton;
