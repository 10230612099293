import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport';

const ALLOWED_KEYS = [
  'id',
  'event_title',
  'description',
  'isPublic',
  'startDate',
  'endDate',
  'distance',
  'intro',
  'country',
  'place',
  'timingType',
  'raceType',
  'rerun',
  'segmentId',
];

const restrictedRoomsExporter = (rooms: any) => {
  const roomsForExport = rooms.map((room: any) => {
    return Object.keys(room)
      .filter((key) => ALLOWED_KEYS.includes(key))
      .reduce((obj: any, key: string) => {
        obj[key] = room[key];
        return obj;
      }, {});
  });

  jsonExport(roomsForExport, (err: any, csv: any) => {
    downloadCSV(csv, 'rooms'); // download as 'rooms.csv` file
  });
};

export default restrictedRoomsExporter;
