import countries from 'i18n-iso-countries';
import React, { useEffect } from 'react';
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  EditButton,
  Filter,
  FormDataConsumer,
  FormTab,
  List,
  number,
  NumberField,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  ShowButton,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
} from 'react-admin';
import { useForm, useFormState } from 'react-final-form';

import BackendFileUpload from '../../common/backend-file-upload';
import { Translatable } from '../../common/translatable';
import CloneRaceButton from './clone-race-button';
import restrictedRoomsExporter from './restricted-rooms-exporter';

enum TimingType {
  DISTANCE = 'distance',
  TIME = 'time',
}

enum RaceType {
  FIXED_TIME = 'fixed_time',
  OPEN_WINDOW = 'open_window',
  SEGMENT = 'segment',
  COLLECT = 'collect',
}

enum SportType {
  RUNNING = 'running',
  CYCLING = 'cycling',
  XCSKI = 'xcski',
  SUP = 'sup',
  WALKING = 'walking',
}

const CATEGORIES = [
  { id: 0, name: 'Main' },
  { id: 1, name: 'Not main (prev image not shown)' },
  { id: 2, name: 'Migros Race' },
  { id: 3, name: 'Berlin' },
  { id: 4, name: 'Munich' },
  { id: 5, name: 'Lisbon' },
  { id: 6, name: 'Swiss running' },
  { id: 7, name: 'Paris 10k' },
  { id: 8, name: 'BMC' },
  { id: 9, name: 'WYD' },
  { id: 10, name: 'VARVET' },
  { id: 11, name: 'PROSEGUR' },
  { id: 12, name: 'BAUHAUS' },
];

const countryValidator = (value: any, allValues: any) => {
  if (typeof value === 'string') {
    if (value.length === 2 && value === value.toUpperCase()) {
      if (countries.isValid(value)) {
        return undefined;
      }
    }
  }
  return 'not a valid country ISO code has to be capital letters';
};

const timezoneHelperText = `Input Timezone: ${
  String(new Date()).split(' ')[5]
} / Converter: https://greenwichmeantime.com/time-gadgets/time-zone-converter/`;

// endDate is dependent from raceType
const EndDateInput = (props: any) => {
  const form = useForm();
  const { values } = useFormState();
  const editEndDate =
    values.raceType === RaceType.OPEN_WINDOW || values.raceType === RaceType.SEGMENT || values.raceType === RaceType.COLLECT;

  // Synchronize endDate and startDate if needed
  useEffect(() => {
    if (form && values && !editEndDate) {
      form.change('endDate', values.startDate);
    }

    // Set DEPRECATED start_date and start_time
    if (values.startDate) {
      const date = new Date(values.startDate);
      form.change('start_date', values.startDate);
      form.change('start_time', date.getHours().toString() + ':' + date.getMinutes().toString());
    }
  }, [form, values, editEndDate]);

  return <DateTimeInput disabled={!editEndDate} source="endDate" {...props} />;
};

const RoomFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Event Title" source="event_title||$contL" alwaysOn />
    {props.permissions === 'superadmin' && <TextInput label="Tenant" source="tenant.name||$contL" alwaysOn />}
    <TextInput label="Event Id" source="id||$eq" />
    <SelectInput
      source="sportType||$eq"
      label="Sport"
      choices={[
        { id: SportType.RUNNING, name: 'Running' },
        { id: SportType.CYCLING, name: 'Cycling' },
        { id: SportType.XCSKI, name: 'X-Country Ski' },
        { id: SportType.SUP, name: 'SUP' },
        { id: SportType.WALKING, name: 'Walking' },
      ]}
      alwaysOn
    />
    <BooleanInput source="featured||$eq" label="Featured" alwaysOn />
  </Filter>
);

export const RoomList = (props: any) => (
  <List
    {...props}
    filters={<RoomFilter {...props} />}
    exporter={props.permissions === 'superadmin' ? props.exporter : restrictedRoomsExporter}
    sort={{ field: 'id', order: 'DESC' }}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      {props.permissions === 'superadmin' && <TextField source="tenant.name" label="Tenant" />}
      <TextField source="event_title" />
      <DateField source="startDate" />
      <DateField source="endDate" />
      <TextField source="sportType" />
      <TextField source="distance" />
      <BooleanField source="isPublic" />
      <BooleanField source="featured" />
      <TextField source="country" />
      <TextField source="place" />
      {props.permissions === 'superadmin' && <NumberField source="category_id" />}
      <ShowButton />
      <EditButton />
      <CloneRaceButton />
    </Datagrid>
  </List>
);

export const RoomCreate = (props: any) => {
  const defaultValues = {
    raceType: props.permissions === 'superadmin' ? RaceType.OPEN_WINDOW : RaceType.SEGMENT, // swissrunning can only have segments
    timingType: TimingType.DISTANCE,
    rerun: true,
    event_title: '',
    description: '',
    isPublic: true,
    intro: '',
    country: 'CH',
    place: 'Bern',
    category_id: 0,
    agegroupRanking: true,
    teamEnable: false,
  };

  return (
    <Create {...props}>
      <SimpleForm redirect="show" fullWidth defaultValue={defaultValues}>
        {props.permissions === 'superadmin' && (
          <ReferenceInput label="Tenant" source="tenant.id" reference="tenant">
            <SelectInput optionText={(record: any) => `${record.id} | ${record.name}`} validate={required()} />
          </ReferenceInput>
        )}
        <TextInput source="event_title" validate={required()} fullWidth />
        <Translatable source="event_title">
          <TextInput label="Event Title" fullWidth />
        </Translatable>
        <TextInput label="Event Description" multiline source="description" fullWidth validate={required()} />
        <Translatable source="description">
          <TextInput label="Description" multiline fullWidth />
        </Translatable>
        <BooleanInput source="isPublic" validate={required()} fullWidth />
        <BooleanInput source="rerun" fullWidth />
        <NumberInput
          source="distance"
          label="Distance in m or Time in s depending on race type"
          helperText="Put 0 in case of COLLECT mode"
          validate={[required(), number()]}
          fullWidth
        />
        {props.permissions === 'superadmin' && (
          <SelectInput
            source="timingType"
            label="Timing type distance or time"
            choices={[
              { id: TimingType.DISTANCE, name: 'Distance (default)' },
              { id: TimingType.TIME, name: 'Time' },
            ]}
            validate={[required()]}
            fullWidth
          />
        )}
        {props.permissions === 'superadmin' && (
          <SelectInput
            source="raceType"
            label="Race type"
            choices={[
              { id: RaceType.FIXED_TIME, name: 'fixed_time' },
              { id: RaceType.OPEN_WINDOW, name: 'open_window' },
              { id: RaceType.SEGMENT, name: 'segment' },
              { id: RaceType.COLLECT, name: 'collect' },
            ]}
            validate={[required()]}
            fullWidth
          />
        )}
        {props.permissions === 'superadmin' && (
          <SelectInput
            source="sportType"
            label="Sport"
            choices={[
              { id: SportType.RUNNING, name: 'Running' },
              { id: SportType.CYCLING, name: 'Cycling' },
              { id: SportType.XCSKI, name: 'X-Country Ski' },
              { id: SportType.SUP, name: 'SUP' },
              { id: SportType.WALKING, name: 'Walking' },
            ]}
            validate={[required()]}
            fullWidth
          />
        )}
        <DateTimeInput source="startDate" validate={required()} helperText={timezoneHelperText} fullWidth />
        <EndDateInput validate={required()} helperText={timezoneHelperText} fullWidth />
        <TextInput label="Course Description" multiline source="intro" fullWidth />
        <Translatable source="intro" label="Course Description Translation">
          <TextInput label="Course Description" multiline fullWidth />
        </Translatable>
        <TextInput source="country" validate={[required(), countryValidator]} fullWidth />
        <TextInput source="place" validate={required()} fullWidth />
        <Translatable source="place">
          <TextInput label="Place" fullWidth />
        </Translatable>
        {props.permissions === 'superadmin' && <TextInput source="web_link" fullWidth />}
        {props.permissions === 'superadmin' && <SelectInput source="category_id" choices={CATEGORIES} validate={[required()]} fullWidth />}
        {props.permissions === 'superadmin' && (
          <BooleanInput source="onlyAllowWomen" label="Only allow women #feminism #equality" fullWidth />
        )}
        {props.permissions === 'superadmin' && <TextField source="code" label="Code for Race mind 6 chars (DEPRECATED)" fullWidth />}
        {props.permissions === 'superadmin' && <BooleanInput source="withPersonalizedCodes" label="Race with personal codes?" fullWidth />}
        {props.permissions === 'superadmin' && <BooleanInput source="agegroupRanking" label="Race allowing age group ranking?" fullWidth />}
        {props.permissions === 'superadmin' && <BooleanInput source="teamEnable" label="Enable Team option?" fullWidth />}
        {props.permissions === 'superadmin' && <BooleanInput source="forceTeams" label="Force Team" fullWidth />}
        {props.permissions === 'superadmin' && <BooleanInput source="userCanHide" label="Allow user to hide from Ranking?" fullWidth />}
        {props.permissions === 'superadmin' && (
          <BooleanInput source="showRCBtn" label="Show RunningCoach Popup after the race to create a plan" fullWidth />
        )}
      </SimpleForm>
    </Create>
  );
};

export const RoomEdit = (props: any) => {
  return (
    <Edit {...props}>
      <TabbedForm redirect="show">
        <FormTab label="General">
          {props.permissions === 'superadmin' && (
            <ReferenceInput label="Tenant" source="tenant.id" reference="tenant">
              <SelectInput optionText={(record: any) => `${record.id} | ${record.name}`} validate={required()} />
            </ReferenceInput>
          )}
          <TextInput source="event_title" validate={required()} fullWidth />
          <Translatable source="event_title">
            <TextInput label="Event Title" fullWidth />
          </Translatable>
          <TextInput label="Event Description" multiline source="description" fullWidth validate={required()} />
          <Translatable source="description">
            <TextInput label="Description" multiline fullWidth />
          </Translatable>
          <BooleanInput source="isPublic" validate={required()} fullWidth />
          <BooleanInput source="rerun" fullWidth disabled={props.permissions !== 'superadmin'} />
          <BooleanInput source="featured" fullWidth disabled={props.permissions !== 'superadmin'} />
          {props.permissions === 'superadmin' && (
            <ReferenceInput
              perPage={100}
              sort={{ field: 'id', order: 'DESC' }}
              label="Campaign"
              source="campaign.id"
              reference="campaign"
              allowEmpty
              fullWidth
            >
              <SelectInput optionText={(record: any) => record.id + ' | ' + record.name} fullWidth />
            </ReferenceInput>
          )}
          {props.permissions === 'superadmin' && (
            <ReferenceInput
              perPage={100}
              sort={{ field: 'id', order: 'DESC' }}
              label="Ranking"
              source="rankingId"
              reference="ranking"
              allowEmpty
              fullWidth
            >
              <SelectInput optionText={(record: any) => record.id + ' | ' + record.name} fullWidth />
            </ReferenceInput>
          )}
          <NumberInput
            source="distance"
            label="Distance in m or Time in s depending on race type"
            validate={[required(), number()]}
            helperText="Put 0 in case of COLLECT mode"
            fullWidth
          />
          {props.permissions === 'superadmin' && (
            <SelectInput
              source="timingType"
              label="Timing type distance or time"
              choices={[
                { id: TimingType.DISTANCE, name: 'Distance (default)' },
                { id: TimingType.TIME, name: 'Time' },
              ]}
              validate={[required()]}
              fullWidth
            />
          )}
          {props.permissions === 'superadmin' && (
            <SelectInput
              source="raceType"
              label="Race type"
              choices={[
                { id: RaceType.FIXED_TIME, name: 'fixed_time' },
                { id: RaceType.OPEN_WINDOW, name: 'open_window' },
                { id: RaceType.SEGMENT, name: 'segment' },
                { id: RaceType.COLLECT, name: 'collect' },
              ]}
              validate={[required()]}
              fullWidth
            />
          )}
          {props.permissions === 'superadmin' && (
            <SelectInput
              source="sportType"
              label="Sport"
              choices={[
                { id: SportType.RUNNING, name: 'Running' },
                { id: SportType.CYCLING, name: 'Cycling' },
                { id: SportType.XCSKI, name: 'X-Country Ski' },
                { id: SportType.SUP, name: 'SUP' },
                { id: SportType.WALKING, name: 'Walking' },
              ]}
              validate={[required()]}
              fullWidth
            />
          )}
          <DateTimeInput source="startDate" validate={required()} helperText={timezoneHelperText} fullWidth />
          <EndDateInput validate={required()} helperText={timezoneHelperText} fullWidth />

          <TextInput label="Course Description" multiline source="intro" fullWidth />
          <Translatable source="intro" label="Course Description Translation">
            <TextInput label="Course Description" multiline fullWidth />
          </Translatable>
          <TextInput source="country" validate={[required(), countryValidator]} fullWidth />
          <TextInput source="place" validate={required()} fullWidth />
          <Translatable source="place">
            <TextInput label="Place" fullWidth />
          </Translatable>
          {props.permissions === 'superadmin' && <TextInput source="web_link" fullWidth />}
          {props.permissions === 'superadmin' && (
            <SelectInput source="category_id" choices={CATEGORIES} validate={[required()]} fullWidth />
          )}
          {props.permissions === 'superadmin' && (
            <BooleanInput source="onlyAllowWomen" label="Only allow women #feminism #equality" fullWidth />
          )}
          {props.permissions === 'superadmin' && <TextField source="code" label="Code for Race mind 6 chars (DEPRECATED)" fullWidth />}

          {props.permissions === 'superadmin' && (
            <BooleanInput source="withPersonalizedCodes" label="Race with personalized code?" fullWidth />
          )}
          {props.permissions === 'superadmin' && (
            <BooleanInput source="agegroupRanking" label="Race allowing age group ranking?" fullWidth />
          )}
          {props.permissions === 'superadmin' && <BooleanInput source="teamEnable" label="Enable team for this race?" fullWidth />}
          {props.permissions === 'superadmin' && <BooleanInput source="forceTeams" label="Force Team" fullWidth />}
          {props.permissions === 'superadmin' && <BooleanInput source="userCanHide" label="Allow user to hide from Ranking?" fullWidth />}
          {props.permissions === 'superadmin' && (
            <BooleanInput source="showRCBtn" label="Show RunningCoach Popup after the race to create a plan" fullWidth />
          )}
        </FormTab>

        {props.permissions === 'superadmin' && (
          <FormTab label="Files">
            {/* Feed */}
            <h3>Feed and Certificate</h3>
            <BackendFileUpload source="feedImage" accept="image/*" {...props} />
            <BackendFileUpload source="eventLogo" accept="image/*" {...props} />
            <BackendFileUpload
              label="Certficate Image (Format 1:1 at least 500x500px)"
              source="mobileCertificateImage"
              accept="image/*"
              {...props}
            />
            {/* BIB */}
            <h3>BIB</h3>
            <BooleanInput source="createBib" label="Create Bib" fullWidth />

            <FormDataConsumer>
              {({ formData, ...rest }: any) =>
                formData.createBib && (
                  <div>
                    <TextInput source="bibFontColor" fullWidth />

                    <BackendFileUpload label="BIB Template (A5 Landscape, 2480x1748 px)" source="bibImage" accept="image/*" {...props} />
                  </div>
                )
              }
            </FormDataConsumer>

            {/* Certificate */}
            <h3>Certificate</h3>
            <BooleanInput source="createCertificate" label="Create Certificate" fullWidth />
            <FormDataConsumer>
              {({ formData, ...rest }: any) =>
                formData.createCertificate && (
                  <div>
                    <TextInput source="certificateFontColor" fullWidth />

                    <BackendFileUpload
                      label="Certificate Template (A4 Portrait, 2480x3508 px)"
                      source="certImage"
                      accept="image/*"
                      {...props}
                    />
                  </div>
                )
              }
            </FormDataConsumer>
          </FormTab>
        )}

        {props.permissions === 'superadmin' && (
          <FormTab label="Mails">
            {/* Newsletters */}
            <h3>Newsletters</h3>

            <h4>Available placeholders</h4>
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th style={{ textAlign: 'left' }}>Placeholder</th>
                  <th style={{ textAlign: 'left' }}>Pre-Race</th>
                  <th style={{ textAlign: 'left' }}>Post-Race</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>$firstname</td>
                  <td>User first name</td>
                  <td>User first name</td>
                </tr>
                <tr>
                  <td>$lastname</td>
                  <td>User last name</td>
                  <td>User last name</td>
                </tr>
                <tr>
                  <td>$eventTitle</td>
                  <td>Translated event title</td>
                  <td>Translated event title</td>
                </tr>
                <tr>
                  <td>$favorites</td>
                  <td>List of recent favorites</td>
                  <td>Results of recent favorites</td>
                </tr>
                <tr>
                  <td>$pastResults</td>
                  <td>List of users recent results (Migros only)</td>
                  <td>List of users recent results (Migros only)</td>
                </tr>
                <tr>
                  <td>$prizeWinners</td>
                  <td>-</td>
                  <td>List of users which won a prize</td>
                </tr>
                <tr>
                  <td>$time</td>
                  <td>-</td>
                  <td>Run time</td>
                </tr>
                <tr>
                  <td>$distance</td>
                  <td>Race distance (distance-based races only)</td>
                  <td>Race distance time (or distance)</td>
                </tr>
                <tr>
                  <td>$rank</td>
                  <td>-</td>
                  <td>Rank</td>
                </tr>
                <tr>
                  <td>$passwordBlock</td>
                  <td>Only for SignupMail if users get imported</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>{'$datalist[<name>][<selector(optional)>]'}</td>
                  <td>{'Value from unused datalist item with name = <name> based on <selector>'}</td>
                  <td>{'Value from unused datalist item with name = <name> based on <selector>'}</td>
                </tr>
              </tbody>
            </table>
            <TextInput source="newsletterTemplateId" label="Newsletter Template ID" fullWidth />
            <TextInput
              source="emailFromAddress"
              label="Email Sender Address"
              helperText="Format: <email>, <name> | Example: test@tester.com, Test Tester"
              fullWidth
            />
            <BooleanInput
              source="newsletterPreSend"
              label="Send pre-race Newsletter (24h before startDate)"
              helperText="Note: Sent when startDate between [NOW, NOW+24h]"
              fullWidth
            />
            <FormDataConsumer>
              {({ formData, ...rest }: any) =>
                formData.newsletterPreSend && (
                  <div>
                    <BooleanInput source="newsletterPreAttachBib" label="Attach Bib" fullWidth />
                    <SelectInput
                      source="newsletterPreRecipients"
                      choices={[
                        {
                          id: 'former_migros',
                          name: 'Former Migros participants',
                        },
                        {
                          id: 'signed_up',
                          name: 'Already signed up participants',
                        },
                      ]}
                    />
                    <Translatable source="newsletterPreSubject">
                      <TextInput label="Pre-Race Newsletter Subject" fullWidth />
                    </Translatable>
                    <Translatable source="newsletterPreIntro">
                      <TextInput label="Pre-Race Newsletter Intro" multiline fullWidth />
                    </Translatable>
                    <Translatable source="newsletterPreRunningAdvice">
                      <TextInput label="Pre-Race Newsletter Running Advice" multiline fullWidth />
                    </Translatable>
                    <Translatable source="newsletterPreOutro">
                      <TextInput label="Pre-Race Newsletter Outro" fullWidth />
                    </Translatable>
                  </div>
                )
              }
            </FormDataConsumer>
            <BooleanInput
              source="newsletterPostSend"
              label="Send post-race Newsletter (2h after endDate)"
              helperText="Note: Sent when endDate between [NOW-12h, NOW-2h]"
              fullWidth
            />
            <FormDataConsumer>
              {({ formData, ...rest }: any) =>
                formData.newsletterPostSend && (
                  <div>
                    <BooleanInput source="newsletterPostAttachCertificate" label="Attach Certificate" fullWidth />

                    <Translatable source="newsletterPostSubject">
                      <TextInput label="Post-Race Newsletter Subject" fullWidth />
                    </Translatable>
                    <Translatable source="newsletterPostIntro">
                      <TextInput label="Post-Race Newsletter Intro" multiline fullWidth />
                    </Translatable>
                    <Translatable source="newsletterPostRunningAdvice">
                      <TextInput label="Post-Race Newsletter Running Advice" multiline fullWidth />
                    </Translatable>
                    <Translatable source="newsletterPostOutro">
                      <TextInput label="Post-Race Newsletter Outro" fullWidth />
                    </Translatable>
                  </div>
                )
              }
            </FormDataConsumer>

            {/* SignupRaceMail */}
            <BooleanInput source="signupRaceMail" label="Send Mail when a user signs up for the race" fullWidth />

            <FormDataConsumer>
              {({ formData, ...rest }: any) =>
                formData.signupRaceMail && (
                  <div>
                    <Translatable source="signupRaceMailSubject">
                      <TextInput label="Signup Race Mail Subject" fullWidth />
                    </Translatable>
                    <Translatable source="signupRaceMailGreeting">
                      <TextInput label="Signup Race Mail Greeting" fullWidth />
                    </Translatable>
                    <Translatable source="signupRaceMailText">
                      <TextInput label="Signup Race Mail Text" multiline fullWidth />
                    </Translatable>
                    <Translatable source="signupRaceMailGoodbye">
                      <TextInput label="Signup Race Mail Goodbye" fullWidth />
                    </Translatable>
                    <BooleanInput source="signupRaceMailAttachBib" label="Attach Bib to Signup Mail" fullWidth />
                  </div>
                )
              }
            </FormDataConsumer>

            {/* FinishRaceMail */}
            <BooleanInput source="finishRaceMail" label="Send Mail when a user finishes the race" fullWidth />

            <FormDataConsumer>
              {({ formData, ...rest }: any) =>
                formData.finishRaceMail && (
                  <div>
                    <Translatable source="finishRaceMailSubject">
                      <TextInput label="Finish Race Mail Subject" fullWidth />
                    </Translatable>
                    <Translatable source="finishRaceMailGreeting">
                      <TextInput label="Finish Race Mail Greeting" fullWidth />
                    </Translatable>
                    <Translatable source="finishRaceMailText">
                      <TextInput label="Finish Race Mail Text" multiline fullWidth />
                    </Translatable>
                    <Translatable source="finishRaceMailGoodbye">
                      <TextInput label="Finish Race Mail Goodbye" fullWidth />
                    </Translatable>
                    <BooleanInput source="finishRaceMailAttachCertificate" label="Attach Certificate to Finish Mail" fullWidth />
                  </div>
                )
              }
            </FormDataConsumer>
          </FormTab>
        )}
        {props.permissions === 'superadmin' && (
          <FormTab label="Push">
            {/* Push Notifications */}
            <h3>Push</h3>
            <h4>Available placeholders (TBD later)</h4>
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th style={{ textAlign: 'left' }}>Placeholder</th>
                  <th style={{ textAlign: 'left' }}>Pre-Race</th>
                  <th style={{ textAlign: 'left' }}>Post-Race</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>$firstname</td>
                  <td>User first name</td>
                  <td>User first name</td>
                </tr>
                <tr>
                  <td>$lastname</td>
                  <td>User last name</td>
                  <td>User last name</td>
                </tr>
                <tr>
                  <td>$eventTitle</td>
                  <td>Translated event title</td>
                  <td>Translated event title</td>
                </tr>
                <tr>
                  <td>$time</td>
                  <td>-</td>
                  <td>Run time</td>
                </tr>
                <tr>
                  <td>$distance</td>
                  <td>Race distance (distance-based races only)</td>
                  <td>Race distance time (or distance)</td>
                </tr>
                <tr>
                  <td>$rank</td>
                  <td>-</td>
                  <td>Rank</td>
                </tr>
              </tbody>
            </table>
            {/* 24h before Push */}
            <BooleanInput
              source="pushPre24HSend"
              label="Send pre-race Push (24h before startDate)"
              helperText="Note: Sent when startDate between [NOW, NOW+24h]"
              fullWidth
            />
            <FormDataConsumer>
              {({ formData, ...rest }: any) =>
                formData.pushPre24HSend && (
                  <div>
                    <Translatable source="pushPre24HTitle">
                      <TextInput label="24h Pre-Race Push Title" fullWidth />
                    </Translatable>
                    <Translatable source="pushPre24HText">
                      <TextInput label="24h Pre-Race Push Text" multiline fullWidth />
                    </Translatable>
                  </div>
                )
              }
            </FormDataConsumer>
            {/* 2h before Push */}
            <BooleanInput
              source="pushPre2HSend"
              label="Send pre-race Push (2h before startDate)"
              helperText="Note: Sent when startDate between [NOW, NOW+2h]"
              fullWidth
            />
            <FormDataConsumer>
              {({ formData, ...rest }: any) =>
                formData.pushPre2HSend && (
                  <div>
                    <Translatable source="pushPre2HTitle">
                      <TextInput label="2h Pre-Race Push Title" fullWidth />
                    </Translatable>
                    <Translatable source="pushPre2HText">
                      <TextInput label="2h Pre-Race Push Text" multiline fullWidth />
                    </Translatable>
                  </div>
                )
              }
            </FormDataConsumer>
            {/* Finish Push */}
            <BooleanInput source="finishPush" label="Send Push when a user finishes the race" fullWidth />
            <FormDataConsumer>
              {({ formData, ...rest }: any) =>
                formData.finishPush && (
                  <div>
                    <Translatable source="finishPushTitle">
                      <TextInput label="Finisher Push Title" fullWidth />
                    </Translatable>
                    <Translatable source="finishPushText">
                      <TextInput label="Finisher Push Text" multiline fullWidth />
                    </Translatable>
                  </div>
                )
              }
            </FormDataConsumer>
            {/* Favorite Push */}
            <BooleanInput source="favoritesPush" label="Send Push when a favorite of a user finishes the race" fullWidth />
            <FormDataConsumer>
              {({ formData, ...rest }: any) =>
                formData.favoritesPush && (
                  <div>
                    <Translatable source="favoritesPushTitle">
                      <TextInput label="Favorites Push Title" fullWidth />
                    </Translatable>
                    <Translatable source="favoritesPushText">
                      <TextInput label="Favorites Push Text" multiline fullWidth />
                    </Translatable>
                  </div>
                )
              }
            </FormDataConsumer>
          </FormTab>
        )}
        {props.permissions === 'superadmin' && (
          <FormTab label="Payment">
            <BooleanInput source="paymentEnabled" label="Enable Payment for this race" fullWidth />
            <ReferenceInput
              perPage={1000}
              sort={{ field: 'id', order: 'DESC' }}
              label="Payment"
              source="paymentId"
              reference="payment"
              allowEmpty
              fullWidth
            >
              <SelectInput optionText={(record: any) => record.id + ' | ' + record.name} fullWidth />
            </ReferenceInput>
          </FormTab>
        )}
        <FormTab label="Segment">
          <ReferenceInput perPage={1000} label="Segment" source="segment.id" reference="segment" allowEmpty fullWidth>
            <SelectInput optionText={(record: any) => `${record.id} | ${record.name}`} />
          </ReferenceInput>
          {props.permissions === 'superadmin' && <BooleanInput source="gpxUpload" label="Enable GPX upload" fullWidth />}
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
