import { DataProvider, convertLegacyDataProvider } from 'ra-core';

import createDataProvider from '@fusionworks/ra-data-nest-crud';
import { fetchUtils } from 'react-admin';

export const BASE_URL = process.env.NODE_ENV === 'production'
  ? ( process.env.REACT_APP_IS_STAGING === 'true' ? 'https://backend.staging.virace.app' : 'https://backend.virace.app' )
  : 'http://localhost:3000';

console.log('IS_STAGING', process.env.IS_STAGING, process.env);
export const ADMIN_URL = `${BASE_URL}/admin`;

export const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  console.log('sended token', token);
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

export const dataProvider = convertLegacyDataProvider(createDataProvider(ADMIN_URL, httpClient));

export interface DataTransformations<T> {
  outCreateTransformation?: DataTransformation<T>;
  outUpdateTransformation?: DataTransformation<T>;
  inOneTransformation?: DataTransformation<T>;
  inListTransformation?: DataTransformation<T[]>;
}

type DataHandler<T> = () => Promise<T>;
type DataTransformation<T> = (resource: string, data: T, params: any) => Promise<DataHandler<T> | void> | void;

export function createTransformedDataProvider<T>(provider: DataProvider, transformations: DataTransformations<T>[]): DataProvider {
  return {
    ...provider,
    create: applyOutTransformations(
      provider.create,
      transformations.map((_) => _.outCreateTransformation as DataTransformation<T>).filter((_) => Boolean(_))
    ),
    update: applyOutTransformations(
      provider.update,
      transformations.map((_) => _.outUpdateTransformation as DataTransformation<T>).filter((_) => Boolean(_))
    ),
    getOne: applyInTransformations(
      provider.getOne,
      transformations.map((_) => _.inOneTransformation as DataTransformation<T>).filter((_) => Boolean(_))
    ),
    getList: applyInTransformations(
      provider.getList,
      transformations.map((_) => _.inOneTransformation as DataTransformation<T>).filter((_) => Boolean(_))
    ),
    getMany: applyInTransformations(
      provider.getMany,
      transformations.map((_) => _.inOneTransformation as DataTransformation<T>).filter((_) => Boolean(_))
    ),
  };
}

function applyInTransformations<T>(
  fallbackMethod: (resource: string, params: any) => Promise<any>,
  transformations: DataTransformation<T>[]
) {
  return (resource: string, params: any) => {
    return fallbackMethod(resource, params).then(async (result) => {
      for (const transformation of transformations) {
        await Promise.resolve(transformation(resource, result.data, result));
      }
      return result;
    });
  };
}

function applyOutTransformations<T>(
  fallbackMethod: (resource: string, params: any) => Promise<any>,
  transformations: DataTransformation<T>[]
) {
  return async (resource: string, params: any) => {
    let dataHandler: DataHandler<T> | null = null;
    for (const transformation of transformations) {
      dataHandler = (await Promise.resolve(transformation(resource, params.data, params))) || dataHandler;
    }
    if (dataHandler) {
      return dataHandler();
    }
    return fallbackMethod(resource, params);
  };
}
