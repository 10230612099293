import React from 'react';
import {
  BooleanField,
  Datagrid,
  Filter,
  List,
  NumberField,
  NumberInput,
  Pagination,
  ReferenceManyField,
  Show,
  ShowButton,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  DateInput,
  DateField,
} from 'react-admin';

const ActivityFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Title" source="title" />
    <TextInput label="Sport" source="type" alwaysOn />
    <NumberInput label="User ID" source="userId||$eq" alwaysOn />
    <DateInput label="Date <=" source="startDate||$gt" />
    <DateInput label="Date >=" source="startDate||$lt" />
  </Filter>
);

export const ActivityList = (props: any) => (
  <List {...props} filters={<ActivityFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="user.username" label="Username" />
      <NumberField source="userId" label="User ID" />
      <TextField source="startDate" />
      <TextField source="title" />
      <TextField source="type" />
      <TextField source="source" />
      <NumberField source="distance" />
      <NumberField source="duration" />
      <ShowButton />
    </Datagrid>
  </List>
);

export const ActivityShow = (props: any) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="General">
        <TextField source="id" />
        <TextField source="title" />
        <DateField source="startDate" />
        <TextField source="utcOffset" />
        <TextField source="type" />
        <NumberField source="distance" />
        <NumberField source="duration" />
        <TextField source="source" />
        <TextField source="boundingBoxNE" />
        <TextField source="boundingBoxSW" />
      </Tab>
      <Tab label="User">
        <TextField source="user.id" label="ID" />
        <TextField source="user.username" label="Username" />
        <TextField source="user.email" label="Email" />
      </Tab>
      <Tab label="Matches">
        <ReferenceManyField
          pagination={<Pagination />}
          perPage={10}
          label="ActivityRoomMatch"
          reference="activity-room-match"
          target="activityId"
        >
          <Datagrid>
            <NumberField source="id" />
            <NumberField source="roomId" />
            <BooleanField source="used" />
            <BooleanField source="triedToParticipate" />
            <TextField source="participateError" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
