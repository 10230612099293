import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  ShowButton,
  EditButton,
  Filter,
  required,
  Show,
  DateField,
  TabbedShowLayout,
  Tab,
  NumberInput,
  NumberField,
} from 'react-admin';

const TeamFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput label="Race ID" source="roomId||$eq" alwaysOn />
    <TextInput label="Name" source="name" alwaysOn/>
  </Filter>
);

export const TeamList = (props: any) => (
  <List {...props} filters={<TeamFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <NumberField source="roomId" />
      <TextField source="name" />
      <DateField source="createdAt" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

const CommonFormPart = (props: any) => (
  <span>
    <NumberInput source="roomId" fullWidth validate={[required()]} />
    <TextInput source="name" fullWidth validate={[required()]} />
  </span>
);

export const TeamCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <CommonFormPart {...props} />
    </SimpleForm>
  </Create>
);

export const TeamEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm redirect="list">
      <CommonFormPart {...props} />
    </SimpleForm>
  </Edit>
);

export const TeamShow = (props: any) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Prize">
        <TextField source="id" />
        <NumberField source="roomId" />
        <TextField source="name" />
        <TextField source="createdAt" />
        <TextField source="updatedAt" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
