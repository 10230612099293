import React from 'react';
import { cloneElement } from 'react';
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  ShowButton,
  EditButton,
  Filter,
  required,
  minLength,
  BooleanField,
  Show,
  BooleanInput,
  NumberField,
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
  sanitizeListRestProps,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  SimpleShowLayout,
} from 'react-admin';

const generateRandomSecret = (size: number) => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');

const ListActions = (props: any) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { currentSort, resource, displayedFilters, filterValues, basePath, showFilter, total } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton disabled={total === 0} resource={resource} sort={currentSort} filterValues={filterValues} maxResults={10000} />
    </TopToolbar>
  );
};

const ApiUserFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Name" source="name||$contL" alwaysOn />
  </Filter>
);

export const ApiUsersList = (props: any) => (
  <List {...props} title="ApiUsers" filters={<ApiUserFilter />} actions={<ListActions />} sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid rowClick="edit">
      <NumberField source="id" />
      <TextField source="name" />
      <TextField source="rooms" />
      <BooleanField source="enabled" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

const validateRequired = required();
const validateMinLength = minLength(24);

export const ApiUserCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" validate={validateRequired} />
      <TextInput source="secret" initialValue={generateRandomSecret(24)} validate={validateMinLength} />
      <ArrayInput source="rooms">
        <SimpleFormIterator>
          <NumberInput />
        </SimpleFormIterator>
      </ArrayInput>
      <BooleanInput source="enabled" />
    </SimpleForm>
  </Create>
);

function getFormattedApiUser(record: any, source?: string): string {
  const apiUser = source ? record[source] : record;
  if (!apiUser) {
    return '';
  }
  return `ApiUser: ${apiUser.name} (id=${apiUser.id})`;
}

const FormattedApiUserTitle = ({ record, source }: any) => <span>{getFormattedApiUser(record, source)}</span>;

export const ApiUserEdit = (props: any) => (
  <Edit {...props} title={<FormattedApiUserTitle record />}>
    <SimpleForm redirect="list">
      <TextInput source="name" validate={validateRequired} />
      <TextInput source="secret" validate={validateMinLength} />
      <ArrayInput source="rooms">
        <SimpleFormIterator>
          <NumberInput />
        </SimpleFormIterator>
      </ArrayInput>
      <BooleanInput source="enabled" />
    </SimpleForm>
  </Edit>
);

export const ApiUserShow = (props: any) => (
  <Show {...props} title={<FormattedApiUserTitle record />}>
    <SimpleShowLayout>
      <NumberField source="id" />
      <TextField source="name" />
      <TextField source="secret" />
      <TextField source="rooms" />
      <BooleanField source="enabled" />
    </SimpleShowLayout>
  </Show>
);
