import React from 'react';
import { Link } from 'react-router-dom';
import PlusIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

const styles = {
  button: {
    marginTop: '1em',
    marginBottom: '1em'
  }
};

const AddAdditionalDataButton = ({ classes, record }: any) => (
  <Button
    className={classes.button}
    variant="contained"
    component={Link}
    to={`/roomAdditionalData/create?roomId=${record.id}`}
    label="Add data"
    title="Add data"
  >
    <PlusIcon />
  </Button>
);

export default withStyles(styles)(AddAdditionalDataButton);
