import React, { Fragment } from 'react';
import {
  AutocompleteInput,
  CloneButton,
  Create,
  Datagrid,
  DateField,
  Edit,
  FileField,
  FileInput,
  Filter,
  FormDataConsumer,
  List,
  NumberField,
  NumberInput,
  Pagination,
  ReferenceInput,
  required,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
} from 'react-admin';

import { Card, CardContent, Grid } from '@material-ui/core';

import { ADMIN_URL, DataTransformations, httpClient } from '../../common/http';
import { LOCALES } from '../../constants/locales';
import { ATTRACTION_KEYWORDS, ATTRACTION_TIMING } from './documentation';

// import * as ReactMarkdown from 'react-markdown';
const ReactMarkdown = require('react-markdown');

export const ATTRACTIONTYPES: { id: string; name: string }[] = [
  { id: 'string_before', name: 'string BEFORE' },
  { id: 'string_during', name: 'string DURING' },
  { id: 'string_after', name: 'string AFTER' },
  { id: 'sound_before', name: 'sound BEFORE' },
  { id: 'sound_during', name: 'sound DURING' },
  { id: 'sound_after', name: 'sound AFTER' },
  { id: 'sound_start', name: 'sound AT THE START' },
  { id: 'sound_finish', name: 'sound FINISH' },
  { id: 'string_start', name: 'string AT THE START' },
  { id: 'string_finish', name: 'string FINISH' },
];

async function attractionsOutTransformation(resource: string, attraction: any) {
  if (resource === 'attraction') {
    if (attraction) {
      // This is needed to store the roomId in the backend correctly
      if (attraction.roomId) {
        attraction.room = attraction.roomId;
      }

      if (attraction.cloneFromRoomId) {
        return () => {
          const dto = { fromRaceId: attraction.cloneFromRoomId, toRaceId: attraction.roomId };
          return httpClient(ADMIN_URL + '/attraction/clone-from-race', {
            method: 'POST',
            body: JSON.stringify(dto),
          }).then((data: any) => ({ data }));
        };
      }

      if (!attraction.soundFile) {
        attraction.deleteSound = Boolean(attraction.soundFile);
      } else {
        if (attraction.soundFile.rawFile) {
          const formData = new FormData();
          formData.append('newMP3', attraction.soundFile.rawFile);
          delete attraction.soundFile;
          formData.append('attraction', JSON.stringify(attraction));
          return () =>
            httpClient(ADMIN_URL + '/attraction/with-sound', {
              method: 'POST',
              body: formData,
            }).then((data: any) => ({ data }));
        }
        delete attraction.soundFile;
      }
    }
  }
}

async function attractionsInTransformation(resource: string, attraction: any) {
  if (resource === 'attraction') {
    // This is needed because the roomId won't get transmitted correctly
    if (attraction && attraction.room) {
      attraction.roomId = attraction?.room?.id || null;
    }
  }
}

export const attractionsTransformations: DataTransformations<any> = {
  outCreateTransformation: attractionsOutTransformation,
  outUpdateTransformation: attractionsOutTransformation,
  inOneTransformation: attractionsInTransformation,
};

const AttractionsFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Event Id" source="room.id||$eq" alwaysOn />
    <TextInput label="Event Title" source="room.event_title||$contL" alwaysOn />
  </Filter>
);

const postRowStyle = (record: any) => ({
  backgroundColor: record && record.type && record.type.split('_')[0] === 'sound' ? '#efe' : 'white',
});
const PostPagination = (props: any) => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 250]} {...props} />;
export const AttractionsList = (props: any) => (
  <List {...props} filters={<AttractionsFilter />} pagination={<PostPagination />} sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid rowClick="edit" rowStyle={postRowStyle}>
      <TextField source="id" />
      <TextField source="room.event_title" />
      <NumberField label="Distance OR Seconds" source="distance" />
      <TextField source="speakString" />
      <TextField source="language" />
      <DateField source="createdDate" />
      <NumberField source="room.id" />
      <TextField source="type" />
      <ShowButton />
      <CloneButton />
    </Datagrid>
  </List>
);

const documentationBlock = (
  <div style={{ width: '100%' }}>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Card>
          <CardContent>
            <ReactMarkdown source={ATTRACTION_TIMING} fullWidth></ReactMarkdown>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Card>
          <CardContent>
            <ReactMarkdown source={ATTRACTION_KEYWORDS} fullWidth></ReactMarkdown>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </div>
);

const validateAttractionCreation = (values: any) => {
  const errors: any = {};
  if (!values.roomId) {
    errors.roomId = ['Race is required'];
  }

  if (!values.cloneFromRoomId) {
    if (values.distance === undefined || values.distance === '') {
      errors.distance = ['Distance is required'];
    }
    if (!values.language) {
      errors.language = ['Language is required'];
    }
    if (!values.type) {
      errors.type = ['Type is required'];
    }
  }

  return errors;
};

export const AttractionsCreate = (props: any) => {
  return (
    <Create {...props}>
      <SimpleForm redirect="show" validate={validateAttractionCreation}>
        <ReferenceInput
          perPage={400}
          sort={{ field: 'id', order: 'DESC' }}
          label="Race"
          source="roomId"
          reference="room"
          validate={[required()]}
          fullWidth
        >
          <SelectInput
            optionText={(record: any) => record.id + ' | ' + record.event_title + ' | ' + record.start_date}
            optionValue="id"
            fullWidth
          />
        </ReferenceInput>
        <ReferenceInput
          perPage={20}
          sort={{ field: 'id', order: 'DESC' }}
          label="Clone from Race"
          source="cloneFromRoomId"
          reference="room"
          fullWidth
          filterToQuery={(s: string) => ({ 'event_title||$contL||': s.length > 2 ? s : 'Migros' })}
        >
          <AutocompleteInput
            allowEmpty={true}
            optionText={(record: any) => record.id + ' | ' + record.event_title + ' | ' + record.start_date}
            optionValue="id"
          />
        </ReferenceInput>
        <NumberInput label="Distance IN METERS or seconds depending on type" source="distance" fullWidth />
        <TextInput label="String to say" multiline source="speakString" fullWidth />
        <SelectInput source="language" choices={LOCALES} />
        <SelectInput source="type" choices={ATTRACTIONTYPES} />
        <FileInput source="soundFile" label="Add Sound only .mp3 works!" accept="audio/*">
          <FileField source="src" title="title" />
        </FileInput>
        <FormDataConsumer>
          {({ formData, ...rest }: any) => {
            return (
              <audio controls>
                <source src={formData.sound} type="audio/mpeg"></source>
                Your browser does not support the audio element.
              </audio>
            );
          }}
        </FormDataConsumer>
        {documentationBlock}
      </SimpleForm>
    </Create>
  );
};

const AttractionsEditTitle = ({ record }: any) => <span>{`${record.firstname} ${record.lastname}`}</span>;

export const AttractionsEdit = (props: any) => (
  <Edit {...props} title={<AttractionsEditTitle record />}>
    <SimpleForm redirect="list">
      <ReferenceInput
        perPage={200}
        sort={{ field: 'id', order: 'DESC' }}
        label="Race"
        source="room.id"
        reference="room"
        validate={[required()]}
        fullWidth
      >
        <SelectInput
          optionText={(record: any) => record.id + ' | ' + record.event_title + ' | ' + record.start_date}
          optionValue="id"
          fullWidth
        />
      </ReferenceInput>
      <NumberInput label="Distance in METERS OR negative seconds before start" source="distance" fullWidth validate={[required()]} />
      <TextInput label="String to say" multiline source="speakString" fullWidth />
      <SelectInput source="language" choices={LOCALES} validate={[required()]} />
      <SelectInput source="type" choices={ATTRACTIONTYPES} validate={[required()]} />
      <FileInput source="soundFile" label="Add Sound" accept="audio/*">
        <FileField source="src" title="title" />
      </FileInput>
      <FormDataConsumer>
        {({ formData, ...rest }: any) => {
          // console.log('formData', formData);
          return formData.sound ? (
            <Fragment>
              <a href={formData.sound} style={{ display: 'block', marginBottom: '10px' }}>
                {formData.sound}
              </a>
              <audio controls>
                <source src={formData.sound} type="audio/mpeg"></source>
                Your browser does not support the audio element.
              </audio>
            </Fragment>
          ) : null;
        }}
      </FormDataConsumer>
      {documentationBlock}
    </SimpleForm>
  </Edit>
);

export const Attractionshow = (props: any) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Attractions">
          <TextField source="id" />
          <TextField source="room.event_title" />
          <NumberField source="distance" />
          <TextField source="speakString" />
          <TextField source="language" />
          <DateField source="createdDate" />
          <NumberField source="room.id" />
          <TextField source="type" />
          <TextField source="sound" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
