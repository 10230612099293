import { BASE_URL } from './http';

interface LoginParams {
  username: string;
  password: string;
  tenant: string;
}

interface LoginData {
  user: { isAdmin: boolean; token: string; tenant: string };
}

export default {
  login: ({ username, password, tenant }: LoginParams) => {
    console.log('tenant', tenant);
    const body = JSON.stringify({
      user: {
        email: username,
        password,
      },
    });
    const request = new Request(`${BASE_URL}/api/login`, {
      method: 'POST',
      body: body,
      headers: new Headers({ 'Content-Type': 'application/json', 'x-tenant': tenant || 'virace' }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((response: LoginData) => {
        if (response.user.isAdmin) {
          console.log('success');
          localStorage.setItem('token', response.user.token);
          localStorage.setItem('role', getUserRole(response.user));
        } else {
          throw new Error('Unauthorized');
        }
      });
  },
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    return Promise.resolve();
  },
  checkError: (error: any) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
  },
  getPermissions: () => {
    const role = localStorage.getItem('role');
    return role ? Promise.resolve(role) : Promise.reject();
  },
};

const getUserRole = (user: any): string => {
  console.log(user);
  if (user.isAdmin && user.tenant === 'virace') {
    return 'superadmin';
  } else if (user.isAdmin) {
    return 'admin';
  }
  return 'user';
};
