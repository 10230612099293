import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  DeleteButton,
  Edit,
  EditButton,
  EmailField,
  ExportButton,
  Filter,
  List,
  NumberField,
  NumberInput,
  Pagination,
  ReferenceInput,
  ReferenceManyField,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  email,
  required,
  sanitizeListRestProps,
  useListContext,
} from 'react-admin';
import React, { cloneElement } from 'react';

import { COUNTRIES } from '../../constants/countries';
import { ImportCSV } from './import-csv';
import { LOCALES } from '../../constants/locales';
import PropTypes from 'prop-types';
import restrictedUsersExporter from './restricted-users-exporter';

const ListActions = (props: any) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { currentSort, resource, displayedFilters, filterValues, basePath, showFilter, total } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton disabled={total === 0} resource={resource} sort={currentSort} filterValues={filterValues} maxResults={10000} />
    </TopToolbar>
  );
};

const UserFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Lastname" source="lastname||$contL" alwaysOn />
    <TextInput label="Firstname" source="firstname||$contL" alwaysOn />
    <TextInput label="Username" source="username||$contL" alwaysOn />
    <TextInput label="Badge" source="badge||$cont" alwaysOn />
    <TextInput label="Email" source="email||$contL" alwaysOn />
    <BooleanInput label="Organizer" source="isOrganizer||$eq" />
    <BooleanInput label="Admin" source="isAdmin||$eq" />
    <TextInput label="ID+" source="id||$gt" />
    <TextInput label="ID-" source="id||$lt" />
  </Filter>
);

export const UsersList = (props: any) => (
  <React.Fragment>
    <List
      {...props}
      filters={<UserFilter />}
      exporter={props.permissions === 'superadmin' ? props.exporter : restrictedUsersExporter}
      actions={<ListActions />}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid rowClick="edit">
        <NumberField source="id" />
        {props.permissions === 'superadmin' && <TextField source="tenant.name" />}
        <TextField source="username" />
        <TextField source="firstname" />
        <TextField source="lastname" />
        <TextField source="country" />
        <TextField source="locale" />
        <EmailField source="email" />
        <BooleanField source="isTester" />
        <BooleanField source="isAdmin" />
        <BooleanField source="isOrganizer" />
        {props.permissions === 'superadmin' && <NumberField source="runningCoachId" />}
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
    {props.permissions === 'superadmin' && <ImportCSV />}
  </React.Fragment>
);

const validateEmail = [required(), email()];
const validateRequired = required();

export const UserCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <TextInput source="firstname" validate={validateRequired} />
      <TextInput source="lastname" validate={validateRequired} />
      <TextInput source="email" validate={validateEmail} />
      <TextInput source="password" type="password" validate={validateRequired} />
    </SimpleForm>
  </Create>
);

export function getFormattedUser(record: any, source?: string): string {
  const user = source ? record[source] : record;
  if (!user) {
    return '';
  }
  return (
    user &&
    (user.firstname + (user.firstname && user.lastname ? ' ' : '') + user.lastname || user.username || 'unknown') + ` (id=${user.id})`
  );
}

const FormattedUserTitle = ({ record, source }: any) => <span>{getFormattedUser(record, source)}</span>;

export const FormattedUserField = ({ source, record = {} }: any) => <span>{getFormattedUser(record, source)}</span>;

FormattedUserField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};
FormattedUserField.defaultProps = {
  addLabel: true,
};

export const UserEdit = ({ permissions, ...props }: any) => (
  <Edit {...props} title={<FormattedUserTitle record />}>
    <SimpleForm redirect="list">
      <TextInput source="username" validate={validateRequired} />
      <TextInput source="firstname" validate={validateRequired} />
      <TextInput source="lastname" validate={validateRequired} />
      <TextInput source="email" validate={validateEmail} />
      {props.permissions === 'superadmin' && <NumberInput source="externalId" label="External ID" />}
      <SelectInput
        source="gender"
        choices={[
          { id: 'female', name: 'Female' },
          { id: 'male', name: 'Male' },
        ]}
        validate={[required()]}
      />
      {permissions === 'superadmin' && (
        <ReferenceInput label="Tenant" source="tenant.id" reference="tenant">
          <SelectInput optionText={(record: any) => `${record.id} | ${record.name}`} />
        </ReferenceInput>
      )}
      <TextInput source="street" />
      <TextInput source="address2" />
      <TextInput source="zip" />
      <TextInput source="city" />
      <TextInput source="state" />
      <AutocompleteInput source="country" choices={COUNTRIES} />
      <SelectInput source="locale" choices={LOCALES} />
      <TextInput source="phone" />
      <TextInput source="team" />
      {props.permissions === 'superadmin' && (
        <SelectInput
          source="tshirt_size"
          choices={[
            { id: 'xs', name: 'xs' },
            { id: 'sm', name: 'sm' },
            { id: 'lxl', name: 'lxl' },
          ]}
        />
      )}
      <NumberInput source="yearOfBirth" />
      <BooleanInput source="usingMile" />
      <BooleanInput source="isTester" />
      <BooleanInput source="isAdmin" />
      <BooleanInput source="isOrganizer" />
    </SimpleForm>
  </Edit>
);

export const UserShow = (props: any) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="User">
        <TextField source="id" />
        <TextField source="tenant.name" />
        <TextField source="badge" />
        <TextField source="username" />
        <TextField source="firstname" />
        <TextField source="lastname" />
        <TextField source="gender" />
        <TextField source="country" />
        <TextField source="locale" />
        <EmailField source="email" />
        <TextField source="street" />
        <TextField source="address2" />
        <TextField source="zip" />
        <TextField source="city" />
        <TextField source="state" />
        <TextField source="phone" />
        <TextField source="team" />
        <TextField source="source" />
        {props.permissions === 'superadmin' && <TextField source="tshirt_size" />}
        <BooleanField source="usingMile" />
        <BooleanField source="isAdmin" />
        <BooleanField source="isOrganizer" />
        <BooleanField source="isTester" />
        {props.permissions === 'superadmin' && [
          <TextField source="deviceToken" />,
          <TextField source="installedVersion" />,
          <TextField source="os" />,
          <TextField source="manufacturer" />,
          <NumberField source="runningCoachId" />,
        ]}
      </Tab>
      <Tab label="Participations">
        <ReferenceManyField pagination={<Pagination />} perPage={20} label="Participation" reference="participation" target="userId">
          <Datagrid>
            <DateField source="createdAt" />
            <TextField source="room.event_title" />
            <DateField source="room.startDate" />
            <TextField source="room.endDate" />
            <TextField source="id" />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      {props.permissions === 'superadmin' && (
        <Tab label="Badges">
          <ReferenceManyField pagination={<Pagination />} perPage={10} label="Badges" reference="badgeUser" target="userId">
            <Datagrid>
              <TextField source="badge.id" />
              <TextField source="badge.title" />
              <DeleteButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      )}
    </TabbedShowLayout>
  </Show>
);
