import React from 'react';
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  ImageField,
  List,
  NumberField,
  NumberInput,
  Pagination,
  ReferenceInput,
  ReferenceManyField,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  TopToolbar,
} from 'react-admin';

import BackendFileUpload from '../../common/backend-file-upload';
import { Translatable } from '../../common/translatable';
import ShowRaces from './show-races-button';

const BadgeFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Title" source="title" />
    <BooleanInput label="Public?" source="public" />
  </Filter>
);

export const BadgeList = (props: any) => (
  <List {...props} filters={<BadgeFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="title" />
      <BooleanField source="public" />
      <TextField source="conditionType" />
      <NumberField source="conditionValue" />
      <EditButton />
    </Datagrid>
  </List>
);

const conditionTypes = [
  { id: 'NUMBER_RACES', name: 'NUMBER_RACES' },
  { id: 'DISTANCE', name: 'DISTANCE' },
  { id: 'TIME', name: 'TIME' },
];

const CommonFormPart = (_props: any) => (
  <span>
    <TextInput source="title" fullWidth />
    <BooleanInput source="public" />
    <TextInput source="icon" fullWidth helperText="Use an emoji. For custom icons, please speak to a dev. (Format :custom_icon:)" />
    <Translatable source="title" label="Title">
      <TextInput label="Title" multiline fullWidth />
    </Translatable>
    <Translatable source="description" label="Description">
      <TextInput label="Description" multiline fullWidth />
    </Translatable>
    <ReferenceInput label="Prize" source="prize.id" reference="prize" fullWidth>
      <SelectInput optionText={(record: any) => record.id + ' | ' + record.title['de'] || record.title[0]} />
    </ReferenceInput>
    <BooleanInput source="winnerMail" fullWidth />
    <Translatable source="winnerMail" label="Winner Mail" helperText="Placeholders: {{badgeName}} {{username}}">
      <TextInput label="Winner Mail" multiline fullWidth />
    </Translatable>
    <TextInput source="raceFilter" fullWidth helperText="Format: https://github.com/nestjsx/crud/wiki/Requests#search" />
    <SelectInput source="conditionType" choices={conditionTypes} fullWidth />
    <NumberInput source="conditionValue" fullWidth />
  </span>
);

export const BadgeCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <CommonFormPart {...props} />
    </SimpleForm>
  </Create>
);

const BadgeEditActions = (props: any) => (
  <TopToolbar>
    <ShowButton variant="contained" basePath={props.basePath} record={props.data} style={{ marginRight: '5px' }} />
    <ShowRaces record={props.data} />
  </TopToolbar>
);

export const BadgeEdit = (props: any) => (
  <Edit {...props} actions={<BadgeEditActions />} undoable={false}>
    <SimpleForm redirect="edit">
      <CommonFormPart {...props} />
      <BackendFileUpload source="image" accept="image/*" {...props} />
    </SimpleForm>
  </Edit>
);

export const BadgeShow = (props: any) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="General">
        <TextField source="id" />
        <TextField source="title" />
        <BooleanField source="public" />
        <TextField source="icon" />
        <BooleanField source="winnerMail" />
        <ImageField source="image.url" title="Image" fullWidth />
      </Tab>
      <Tab label="Users">
        <ReferenceManyField pagination={<Pagination />} perPage={10} label="Users" reference="badgeUser" target="badgeId">
          <Datagrid>
            <TextField source="user.id" />
            <TextField source="user.username" />
            <DeleteButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
