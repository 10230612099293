import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  Filter,
  List,
  NumberField,
  NumberInput,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  TopToolbar,
} from 'react-admin';

import React from 'react';

const DonationFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput label="Race ID" source="participation.roomId||eq" alwaysOn />
    <TextInput label="Race Title" source="participation.room.event_title" alwaysOn />
    <TextInput label="Name" source="name" alwaysOn />
    <TextInput label="Email" source="email" alwaysOn />
    <TextInput label="Mode" source="donationMode" />
    <BooleanInput source="anonymous||$eq" label="Anonymous" alwaysOn />
    <BooleanInput source="paid||$eq" label="Paid" alwaysOn />
    <BooleanInput source="runFinished||$eq" label="Run finished" />
  </Filter>
);

export const DonationList = (props: any) => (
  <List {...props} filters={<DonationFilter />}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField label="Race ID" source="participation.roomId" />
      <TextField source="name" />
      <TextField source="email" />
      <TextField source="donationMode" />
      <BooleanField source="anonymous" />
      <BooleanField source="runFinished" />
      <BooleanField source="paid" />
      <NumberField source="amount" />
      <TextField label="Currency" source="paymentCurrency" />
      <EditButton />
    </Datagrid>
  </List>
);

const donationModes = [
  { id: 'fixed', name: 'fixed' },
  { id: 'variable', name: 'variable' },
];

const paymentCurrencies = [
  { id: 'CHF', name: 'CHF' },
  { id: 'EUR', name: 'EUR' },
  { id: 'USD', name: 'USD' },
];

const CommonFormPart = (props: any) => (
  <span>
    <TextInput source="name" fullWidth />
    <TextInput source="email" fullWidth />
    <NumberInput source="participationId" fullWidth />
    <SelectInput source="donationMode" choices={donationModes} fullWidth />
    <SelectInput source="paymentCurrency" choices={paymentCurrencies} fullWidth />
    <NumberInput source="amount" fullWidth />
    <NumberInput source="finalAmount" fullWidth />
    <NumberInput source="paidAmount" fullWidth />
    <BooleanInput source="paid" fullWidth />
    <BooleanInput source="runFinished" fullWidth />
    <BooleanInput source="anonymous" fullWidth />
  </span>
);

export const DonationCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <CommonFormPart {...props} />
    </SimpleForm>
  </Create>
);

const DonationEditActions = (props: any) => (
  <TopToolbar>
    <ShowButton variant="contained" basePath={props.basePath} record={props.data} style={{ marginRight: '5px' }} />
  </TopToolbar>
);

export const DonationEdit = (props: any) => (
  <Edit {...props} actions={<DonationEditActions />} undoable={false}>
    <SimpleForm redirect="edit">
      <CommonFormPart {...props} />
    </SimpleForm>
  </Edit>
);

export const DonationShow = (props: any) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="General">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="email" />
        <TextField source="token" />
        <TextField source="donationMode" />
        <TextField source="paymentCurrency" />
        <NumberField source="amount" />
        <NumberField source="finalAmount" />
        <NumberField source="paidAmount" />
        <BooleanField source="anonymous" />
        <BooleanField source="runFinished" />
        <BooleanField source="paid" />
      </Tab>
      <Tab label="Participation">
        <TextField source="participation.id" label="Participation ID" />
        <TextField source="participation.time" label="Time" />
        <TextField source="participation.updatedAt" label="UpdatedAt" />
        <TextField source="participation.createdAt" label="CreatedAt" />
      </Tab>
      <Tab label="Race">
        <TextField source="participation.room.id" />
        <TextField source="participation.room.event_title" />
        <TextField source="participation.room.startDate" />
        <TextField source="participation.room.endDate" />
        <TextField source="participation.room.raceType" />
        <TextField source="participation.room.timingType" />
        <TextField source="participation.room.distance" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
