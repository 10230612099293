import * as React from 'react';
import PropTypes from 'prop-types';
import { Notification } from 'react-admin';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { Avatar, Card, Theme } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import LoginForm from './LoginForm';
import { useCheckAuth } from 'ra-core';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

interface Props {
  backgroundImage?: string;
  children: React.ReactNode;
  classes?: object;
  className?: string;
  staticContext?: React.ReactNode;
  theme: object;
}

const defaultTheme = {
  palette: {
    secondary: {
      light: '#6ec6ff',
      main: '#2196f3',
      dark: '#0069c0',
      contrastText: '#fff',
    },
  },
  typography: {
    title: {
      fontWeight: 400,
    },
  },
  sidebar: {
    width: 240,
    closedWidth: 55,
  },
  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        '&$disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      },
    },
  },
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      height: '1px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundImage: 'radial-gradient(circle at 50% 14em, rgb(1 198 168) 0%, #456E77 60%, #456E77 100%)',
    },
    card: {
      minWidth: 300,
      marginTop: '6em',
    },
    avatar: {
      margin: '1em',
      display: 'flex',
      justifyContent: 'center',
    },
    title: {
      display: 'flex',
      justifyContent: 'center',
    },
    icon: {
      backgroundColor: theme.palette.secondary.light,
    },
  }),
  { name: 'RaLogin' }
);

const Login = (props: any) => {
  const { theme, classes: classesOverride, className, children, staticContext, backgroundImage, ...rest } = props;
  const containerRef = React.useRef<HTMLDivElement>();
  const classes = useStyles(props);
  const muiTheme = React.useMemo(() => createMuiTheme(theme), [theme]);
  const checkAuth = useCheckAuth();
  const history = useHistory();
  React.useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        // already authenticated, redirect to the home page
        history.push('/');
      })
      .catch(() => {
        // not authenticated, stay on the login page
      });
  }, [checkAuth, history]);

  return (
    <ThemeProvider theme={muiTheme}>
      <div className={classnames(classes.main, className)} {...rest} ref={containerRef}>
        <Card className={classes.card}>
          <div className={classes.avatar}>
            <Avatar className={classes.icon}>
              <LockIcon />
            </Avatar>
          </div>
          <h3 className={classes.title}>viRACE Admin</h3>
          <LoginForm />
        </Card>
        <Notification />
      </div>
    </ThemeProvider>
  );
};

Login.propTypes = {
  backgroundImage: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  theme: PropTypes.object,
  staticContext: PropTypes.object,
};

Login.defaultProps = {
  theme: defaultTheme,
  children: <LoginForm />,
};

export default Login;
