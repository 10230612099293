import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport';

const exportAttributes: (string | any)[] = [
  'id',
  'time',
  'distance',
  'userUpload',
  'trackId',
  'uphill',
  'downhill',
  'createdAt',
  'updatedAt',
  'codeId',
  'teamId',
  { code: ['id', 'code', 'used', 'team', 'reusable', 'createdAt', 'updatedAt'] },
  { room: ['id', 'event_title', 'startDate', 'endDate', 'raceType', 'timingType', 'segmentId', 'gpxUpload', 'rerun'] },
  { team: ['id', 'name'] },
  {
    user: [
      'id',
      'firstname',
      'lastname',
      'username',
      'email',
      'gender',
      'yearOfBirth',
      'street',
      'address2',
      'zip',
      'city',
      'state',
      'country',
      'locale',
      'team',
      'os',
      'manufacturer',
      'installedVersion',
      'batteryLevel',
      'externalId',
      'tshirt_size',
    ],
  },
];

const extract = (keys: (string | any)[], obj: any): any => {
  const exportObj: any = {};
  for (const key of keys) {
    if (typeof key === 'string') {
      exportObj[key] = obj[key];
    } else {
      const subKey = Object.keys(key)[0];
      if (obj[subKey]) {
        exportObj[subKey] = extract(key[subKey], obj[subKey]);
      }
    }
  }
  return exportObj;
};

const participationsExporter = (participations: any, permissions: string) => {
  let participationsForExport;
  if (permissions === 'superadmin') {
    participationsForExport = participations.map((participation: any) => {
      return extract(exportAttributes, participation);
    });
  } else {
    participationsForExport = participations.map((participation: any) => {
      const participationForExport = extract(exportAttributes, participation);
      // Remove superadmin attributes here
      const { room, code, pinned, hide, ghost, ...reducedParticipationForExport } = participationForExport;
      return reducedParticipationForExport;
    });
  }

  jsonExport(participationsForExport, (err: any, csv: any) => {
    downloadCSV(csv, 'participations'); // download as 'participations.csv` file
  });
};

export default participationsExporter;
