import React, { cloneElement } from 'react';
import {
  BooleanField,
  BooleanInput,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  ExportButton,
  Filter,
  List,
  NumberInput,
  ReferenceInput,
  required,
  sanitizeListRestProps,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
} from 'react-admin';

import { BASE_URL, DataTransformations, httpClient } from '../../common/http';
import codesExporter from './codes-exporter';

async function codesOutTransformation(resource: string, code: any) {
  if (resource === 'codes' && code?.roomId && code.amount && code.amount > 1) {
    return () =>
      httpClient(BASE_URL + '/api/codes/create', {
        method: 'POST',
        body: JSON.stringify({
          codes: {
            roomId: code.roomId,
            amount: code.amount,
            team: code.team,
            reusable: code.resusable,
          },
        }),
      }).then((data: any) => ({ data }));
  }
}

export const codeTransformations: DataTransformations<any> = {
  outCreateTransformation: codesOutTransformation,
};

const CodesFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Event Id" source="room.id||$eq" alwaysOn />
    <TextInput label="Race" source="room.event_title||$contL" alwaysOn />
    <TextInput label="Code" source="code||$contL" alwaysOn />
  </Filter>
);

export const CodesList = (props: any) => (
  <List {...props} filters={<CodesFilter />} exporter={codesExporter} actions={<ListActions />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="code" />
      <BooleanField source="used" />
      <BooleanField source="reusable" />
      <DateField source="createdAt" />
      <TextField source="room.event_title" label="Race" />
      <TextField source="team" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

const ListActions = (props: any) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { currentSort, resource, displayedFilters, filterValues, basePath, showFilter, total } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton disabled={total === 0} resource={resource} sort={currentSort} filterValues={filterValues} maxResults={10000} />
    </TopToolbar>
  );
};

export const CodesCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <ReferenceInput
        perPage={100}
        sort={{ field: 'id', order: 'DESC' }}
        filter={{ 'withPersonalizedCodes||$eq': true }}
        label="Race"
        source="roomId"
        reference="room"
        validate={[required()]}
        fullWidth
      >
        <SelectInput optionText={(record: any) => record.id + ' | ' + record.event_title + ' | ' + record.start_date} fullWidth />
      </ReferenceInput>
      <TextInput source="code" helperText="Set only if amount is empty." />
      <BooleanInput label="Used" source="used" />
      <BooleanInput source="reusable" />
      <NumberInput source="amount" />
      <TextInput source="team" helperText="Optional. If set all users will have that given team name set in there acccount" />
    </SimpleForm>
  </Create>
);

const CodesEditTitle = ({ record }: any) => <span>{`${record.firstname} ${record.lastname}`}</span>;

export const CodesEdit = (props: any) => (
  <Edit {...props} title={<CodesEditTitle record />}>
    <SimpleForm redirect="list">
      <TextField disabled source="id" />
      <TextField disabled source="code" />
      <BooleanInput source="used" />
      <BooleanInput source="reusable" />
      <DateField disabled source="createdAt" />
      <TextField disabled source="room.event_title" />
      <TextField disabled source="room.start_date" />
      <TextField disabled source="team" />
    </SimpleForm>
  </Edit>
);

export const Codeshow = (props: any) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Codes">
        <TextField source="id" />
        <TextField source="code" />
        <BooleanField source="used" />
        <BooleanField source="reusable" />
        <DateField source="createdAt" />
        <TextField source="room.event_title" />
        <TextField source="room.start_date" />
        <TextField source="team" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
