import React from 'react';
import Button from '@material-ui/core/Button';
import { useRefresh } from 'react-admin';
import { APIService } from '../../common/api.service';
import { useToasts } from 'react-toast-notifications';

const PrettifyResult = (res: any) => <pre>{JSON.stringify(res, null, 2)}</pre>;

const ShowRaces = (props: any) => {
  const { addToast } = useToasts();
  const refresh = useRefresh();
  const handleClick = async () => {
    const result = await APIService.calculateRaceIds(props.record.id);
    if (result) {
      refresh();
      addToast(PrettifyResult(result), {
        appearance: 'success',
        autoDismiss: false,
      });
    } else {
      addToast('Something went wrong!', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  return (
    <Button variant="contained" size="small" color="primary" onClick={handleClick}>
      Show Races
    </Button>
  );
};

export default ShowRaces;
