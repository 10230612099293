import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  Edit,
  ShowButton,
  EditButton,
  NumberInput,
  Filter,
  Show,
  DateField,
  NumberField,
  ReferenceManyField,
  TabbedShowLayout,
  Tab,
  Pagination,
} from 'react-admin';

const FavoriteFilter = (props: any) => <Filter {...props}></Filter>;

export const FavoriteList = (props: any) => (
  <List {...props} filters={<FavoriteFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <NumberField source="favParticipation.id" />
      <NumberField source="participation.id" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export const FavoriteCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <NumberInput source="favParticipation.id" />
      <NumberInput source="participation.id" />
    </SimpleForm>
  </Create>
);

export const FavoriteEdit = (props: any) => (
  <Edit {...props} undoable={false}>
    <SimpleForm redirect="show">
      <TextField disabled source="id" />
      <NumberInput source="favParticipation.id" />
      <NumberInput source="participation.id" />
    </SimpleForm>
  </Edit>
);

export const Favoritehow = (props: any) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Favorite">
        <TextField source="id" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <NumberField source="favParticipation.id" />
        <NumberField source="participation.id" />
      </Tab>
      <Tab label="Participation">
        <ReferenceManyField
          pagination={<Pagination />}
          perPage={10}
          label="Participation"
          reference="participation"
          target="id"
          source="participation.id"
        >
          <Datagrid>
            <DateField source="createdAt" />
            <TextField source="room.event_title" />
            <DateField source="room.start_date" />
            <TextField source="room.start_time" />
            <TextField source="id" />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
