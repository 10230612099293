import { Create, Edit, ReferenceInput, SelectInput, SimpleForm, TextInput, required, useEditController } from 'react-admin';

import React from 'react';
import { parse } from 'query-string';

const ReactMarkdown = require('react-markdown');

const ADDITIONAL_DATA_DOCUMENTATION = `
### Available Data Tags \n
#### Auto Participation
ENABLE_AUTO_PARTICIPATION: Automatically use valid track uploads to participate \n
EXCLUDE_SOURCE_AUTO_PARTICIPATION: Add this to exclude specific user sources from Auto-Participation of this race. Example: source1,source2
#### RaceResult
RACE_RESULTS_FETCH_URL: URL to the RaceResult View API \n
RACE_RESULTS_PUSH_URL: URL to the RaceResult Write API \n
#### ASO
ASO_INTERFACE_EVENT_ID: ID of the ASO-Event from which Users/Participations should be imported.
`;

export const RoomAdditionalDataCreate = (props: any) => {
  // Read the post_id from the location which is injected by React Router and passed to our component by react-admin automatically
  const { roomId: roomId_string } = parse(props.location.search);
  const roomId = roomId_string ? parseInt(roomId_string as string, 10) : '';

  const redirect = roomId ? `/room/${roomId}/show/additional-data` : false;

  return (
    <Create {...props}>
      <SimpleForm defaultValue={{ roomId }} redirect={redirect}>
        <ReactMarkdown source={ADDITIONAL_DATA_DOCUMENTATION} fullWidth></ReactMarkdown>
        <ReferenceInput source="roomId" reference="room" validate={required()}>
          <SelectInput optionText={(record: any) => record.id + ' | ' + record.event_title + ' | ' + record.start_date} />
        </ReferenceInput>
        <TextInput source="name" />
        <TextInput source="value" />
      </SimpleForm>
    </Create>
  );
};

export const RoomAdditionalDataEdit = (props: any) => {
  const { record } = useEditController(props);
  const redirect = record?.roomId ? `/room/${record.roomId}/show/additional-data` : false;

  return (
    <Edit {...props}>
      <SimpleForm Fredirect={redirect}>
        <ReactMarkdown source={ADDITIONAL_DATA_DOCUMENTATION} fullWidth></ReactMarkdown>
        <ReferenceInput source="roomId" reference="room" validate={required()}>
          <SelectInput optionText={(r: any) => r.id + ' | ' + r.event_title + ' | ' + r.start_date} />
        </ReferenceInput>
        <TextInput source="name" />
        <TextInput source="value" />
      </SimpleForm>
    </Edit>
  );
};
