import { Circle, MapContainer, Polyline, Popup, TileLayer } from 'react-leaflet';
import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  List,
  NumberField,
  NumberInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  Tab,
  TextField,
  TextInput,
  minValue,
  useEditController,
} from 'react-admin';

import BackendFileUpload from '../../common/backend-file-upload';
import { LatLngTuple } from 'leaflet';
import React from 'react';

// Styling for map components
const startOptions = { color: 'green' };
const finishOptions = { color: 'red' };
const waypointOptions = { color: 'lime' };

const MapField = (props: any) => {
  const segment: any[] = props?.waypoints;
  const firstWP: LatLngTuple = segment?.length ? [segment[0].lat, segment[0].lng] : [48.142685, 11.592296];
  const lastWP: LatLngTuple = segment?.length ? [segment[segment.length - 1].lat, segment[segment.length - 1].lng] : [48.142685, 11.592296];
  const start = segment?.length ? <Circle center={firstWP} pathOptions={startOptions} radius={50}></Circle> : null;
  const finish = segment?.length ? <Circle center={lastWP} pathOptions={finishOptions} radius={50} /> : null;
  const waypoints = segment?.length
    ? segment.map((wp) => (
        <Circle key={wp.id} center={[wp.lat, wp.lng]} pathOptions={waypointOptions} radius={10}>
          <WaypointPopup wp={wp} />
        </Circle>
      ))
    : null;

  return (
    <MapContainer center={firstWP} zoom={18}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {start}
      {finish}
      {waypoints}
      <Polyline positions={segment} />
    </MapContainer>
  );
};

const WaypointPopup = (props: any) => {
  return (
    <Popup>
      <pre>{JSON.stringify(props.wp, null, 2)}</pre>
    </Popup>
  );
};

export const SegmentEdit = (props: any) => {
  const { record } = useEditController(props);

  return (
    <Edit {...props} undoable={false}>
      <SimpleForm redirect="list">
        <TextInput source="name" type="text" fullWidth />
        <MapField waypoints={record?.waypoints || []} fullWidth />
        <NumberInput source="uphill" validate={minValue(0)} />
        <NumberInput source="downhill" validate={minValue(0)} />
        <NumberInput source="analysis_margin" validate={minValue(0)} />
        <NumberInput source="analysis_wp_radius" validate={minValue(0)} />
        <NumberField source="distance" />
        <TextField source="start" />
        <TextField source="finish" />
        <BackendFileUpload refreshOnSuccess label="JSON Upload" source="jsonUpload" accept="application/json" {...props} />
        <BackendFileUpload refreshOnSuccess label="GPX Upload" source="gpxUpload" accept=".gpx" {...props} />
      </SimpleForm>
    </Edit>
  );
};

export const SegmentList = (props: any) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <NumberField source="uphill" />
      <NumberField source="downhill" />
      <NumberField source="distance" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export const SegmentShow = (props: any) => {
  const { record } = useEditController(props);
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <Tab label="Segments">
          <TextField source="id" />
          <TextField source="name" type="text" fullWidth />
          <TextField source="start" />
          <TextField source="finish" />
          <NumberField source="uphill" />
          <NumberField source="downhill" />
          <NumberField source="distance" />
          <MapField waypoints={record?.waypoints || []} fullWidth />
        </Tab>
      </SimpleShowLayout>
    </Show>
  );
};

export const SegmentCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" type="text" fullWidth />
      <NumberInput source="uphill" validate={minValue(0)} />
      <NumberInput source="downhill" validate={minValue(0)} />
      <NumberInput source="analysis_margin" validate={minValue(0)} />
      <NumberInput source="analysis_wp_radius" validate={minValue(0)} />
    </SimpleForm>
  </Create>
);
