export const LOCALES: { id: string, name: string } [] = [
  { id: 'de', name: 'Deutsch' },
  { id: 'en', name: 'English' },
  { id: 'fr', name: 'Francais' },
  { id: 'it', name: 'Italiano' },
  { id: 'pt', name: 'Portugues' },
  { id: 'ru', name: 'Pусский' },
  { id: 'sv', name: 'Svenska' },
  { id: 'zh', name: '中文' },
  { id: 'es', name: 'Española' },
  { id: 'ca', name: 'Catalan' },
  { id: 'cs', name: 'Czech' },
];
