import React, { memo } from 'react';
import { Button, useRefresh } from 'react-admin';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { Queue } from '@material-ui/icons';

import { ADMIN_URL, httpClient } from '../../common/http';

export const CloneRaceButton = (props: any) => {
  const { addToast } = useToasts();
  const refresh = useRefresh();

  const handleClick = (event: any) => {
    // Do not propagate
    event.stopPropagation();

    // Call to backend
    httpClient(ADMIN_URL + `/room/${props.record.id}/clone`, {
      method: 'POST',
      body: null,
    }).then((_data: any) => {
      addToast('Cloned', {
        appearance: 'success',
        autoDismiss: true,
      });
      refresh();
    });
  };

  return (
    <Button component={Link} onClick={handleClick} to={'/room'} label="Clone" title="Clone">
      <Queue />
    </Button>
  );
};

export default memo(CloneRaceButton);
