import {
  BooleanField,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  ExportButton,
  Filter,
  List,
  NumberField,
  Pagination,
  ReferenceManyField,
  Show,
  ShowButton,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  required,
  sanitizeListRestProps,
  useListContext,
} from 'react-admin';
import { Card, CardContent, Grid } from '@material-ui/core';
import React, { cloneElement } from 'react';
const ReactMarkdown = require('react-markdown');

const documentation = `
  ## Attributes Documentation
  ### Transfer Account
  Use this setting to transfer funds directly to a connected stripe account.
  Please be aware, that this cannot be any stripe account. It has to be listed (and enabled) under the "Connect" Navigation item in the Stripe Dashboard.

  More Information about the transfer of funds: [https://stripe.com/docs/connect/direct-charges]

  ### Transfer percentage
  Use this setting only if a "Transfer Account" is set. You can specify how much of each received payment should be transfered to the connected Stripe Account.
  The remaining part of the payment will be added to our Stripe Account as application fee.

  ### Stripe Product
  This is a mandatory setting. In case "Transfer Account" is used, the product needs to be from the connected Stripe account.
`;

const PaymentsFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Id" source="id||$eq" alwaysOn />
    <TextInput label="Name" source="name||$contL" alwaysOn />
    <TextInput label="Stripe Prod." source="stripeProductId||$contL" alwaysOn />
  </Filter>
);

export const PaymentsList = (props: any) => (
  <List {...props} filters={<PaymentsFilter />} actions={<ListActions />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="currency" />
      <TextField source="methods" />
      <TextField source="stripeProductId" />
      <TextField source="transferPercentage" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

const ListActions = (props: any) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { currentSort, resource, displayedFilters, filterValues, basePath, showFilter, total } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton disabled={total === 0} resource={resource} sort={currentSort} filterValues={filterValues} maxResults={10000} />
    </TopToolbar>
  );
};
const CommonFormPart = (props: any) => (
  <div style={{ width: '100%' }}>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Card>
          <CardContent>
            <span>
              <TextInput source="name" validate={[required()]} fullWidth />
              <TextInput source="currency" helperText="Example: CHF, EUR, USD" />
              <TextInput
                source="methods"
                helperText="comma separated list of stripe payment methods. 'card' if empty. Available: alipay, card, ideal, fpx, bacs_debit, bancontact, giropay, p24, eps, sofort, sepa_debit, grabpay, afterpay_clearpay"
                fullWidth
              />
              <TextInput source="stripeProductId" validate={[required()]} fullWidth />

              <TextInput source="transferAccountStripeId" fullWidth />
              <TextInput source="transferPercentage" fullWidth />
            </span>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Card>
          <CardContent>
            <ReactMarkdown source={documentation} fullWidth></ReactMarkdown>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </div>
);

export const PaymentsCreate = (props: any) => {
  return (
    <Create {...props}>
      <SimpleForm redirect="show">
        <CommonFormPart {...props} />
      </SimpleForm>
    </Create>
  );
};

const PaymentsEditTitle = ({ record }: any) => <span>{`${record.id} | ${record.name}`}</span>;

export const PaymentsEdit = (props: any) => (
  <Edit {...props} title={<PaymentsEditTitle record />}>
    <SimpleForm redirect="list">
      <CommonFormPart {...props} />
    </SimpleForm>
  </Edit>
);

const PaymentsShowActions = (props: any) => {
  return (
    <TopToolbar>
      <EditButton basePath={props.basePath} record={props.data} />
      {/* Add your custom actions */}
      <CreateButton basePath="/payment-item" label="Create Item" to={`/payment-item/create?paymentId=${props.id}`} />
    </TopToolbar>
  );
};

export const PaymentsShow = (props: any) => {
  return (
    <Show actions={<PaymentsShowActions {...props} />} {...props}>
      <TabbedShowLayout>
        <Tab label="General">
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="currency" />
          <TextField source="methods" />
          <TextField source="stripeProductId" />
          <TextField source="transferAccountStripeId" />
          <TextField source="transferPercentage" />
          <DateField source="createdAt" />
          <DateField source="updatedAt" />
        </Tab>
        <Tab label="Races">
          <ReferenceManyField pagination={<Pagination />} perPage={10} label="Race" reference="room" target="paymentId" source="id">
            <Datagrid>
              <NumberField source="id" />
              <TextField source="event_title" />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Items">
          <ReferenceManyField
            pagination={<Pagination />}
            perPage={100}
            label="Item"
            reference="payment-item"
            target="paymentId"
            source="id"
          >
            <Datagrid>
              <NumberField source="id" />
              <TextField source="name" />
              <TextField source="type" />
              <BooleanField source="required" />
              <DateField source="createdAt" />
              <DateField source="updatedAt" />
              <ShowButton />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
