import { Box, Button, CssBaseline, makeStyles, MenuItem } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import { APIService } from '../../common/api.service';
import { Field, Form } from 'react-final-form';
import { Select } from 'mui-rff';
import { LOCALES } from '../../constants/locales';
const ReactMarkdown = require('react-markdown');

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  additional: {
    margin: theme.spacing(1),
    minHeight: 'unset',
    backgroundColor: 'white',
  },
}));

const AttractionSimulation = (props: any) => {
  const classes = useStyles();
  const [output, setOutput] = useState('');
  const onSubmit = async (values: any) => {
    const simulation = await APIService.getAttractions(values.roomId, values.language, values.usingMiles);
    setOutput('~~~json' + JSON.stringify(simulation, null, 2) + '\n~~~');
  };
  return (
    <Fragment>
      <div className={classes.additional}>
        <CssBaseline />
        <Form
          onSubmit={onSubmit}
          initialValues={{ roomId: props.id }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Select name="language" label="Select a language to simulate" formControlProps={{ margin: 'none' }}>
                {LOCALES.map((_) => (
                  <MenuItem value={_.id}>{_.name}</MenuItem>
                ))}
              </Select>
              <div>
                <label>Using Miles? </label>
                <Field name="usingMiles" component="input" type="checkbox" />
              </div>
              <Box my={2}>
                <Button variant="contained" color="primary" type="submit" disabled={submitting}>
                  Simulate
                </Button>
              </Box>
            </form>
          )}
        />
      </div>
      <ReactMarkdown source={output} fullWidth></ReactMarkdown>
    </Fragment>
  );
};

export default AttractionSimulation;
