import { Card, CardContent, CardHeader } from '@material-ui/core';
import React, { useCallback } from 'react';
import { SimpleForm, required, SelectInput, NumberInput, Toolbar, SaveButton, useRedirect, useNotify } from 'react-admin';
import { APIService } from '../../common/api.service';
import { LOCALES } from '../../constants/locales';

const SaveToolbar = (props: any) => {
  const { basePath } = props;
  const redirectTo = useRedirect();
  const notify = useNotify();
  const handleSave = useCallback(
    async (values, redirect) => {
      try {
        const attractions = await APIService.createAttractionsLanguageFallback({ ...values });
        console.log('attractions', attractions);
        notify(`Entries Generated: ${attractions.length}`, 'info');
        redirectTo(redirect, basePath, values.roomId, values);
      } catch (e) {
        console.log('error', e);
        notify('error generating ' + e, 'error');
        redirectTo(redirect, basePath, values.roomId, values);
      }
    },
    [redirectTo, basePath, notify]
  );

  return (
    <Toolbar {...props}>
      <SaveButton {...props} onSave={handleSave} />
    </Toolbar>
  );
};

const AttractionsLanguageFallback = (props: any) => {
  console.log(props);
  return (
    <Card>
      <CardHeader title="Generate Language Fallback" />
      <CardContent>
        <p>All sound attractions from "Source language" will be copied to "Target language".</p>
      </CardContent>
      <SimpleForm toolbar={<SaveToolbar />}>
        <NumberInput style={{ display: 'none' }} source="roomId" initialValue={parseInt(props.id, 10)} />
        <SelectInput source="sourceLanguage" choices={LOCALES} validate={[required()]} />
        <SelectInput source="targetLanguage" choices={LOCALES} validate={[required()]} />
      </SimpleForm>
    </Card>
  );
};

export default AttractionsLanguageFallback;
