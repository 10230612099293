import React from 'react';
import {
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  EditButton,
  FormTab,
  List,
  NumberInput,
  ReferenceManyField,
  required,
  SelectArrayInput,
  Show,
  ShowButton,
  SimpleForm,
  Tab,
  TabbedForm,
  TabbedShowLayout,
  TextField,
  TextInput,
} from 'react-admin';

import BackendFileUpload from '../../common/backend-file-upload';
import { Translatable } from '../../common/translatable';
import { LOCALES } from '../../constants/locales';

const CommonFormPart = () => (
  <>
    <TextInput source="name" label="Campaign Name (e.g. munich. Corresponds to name of subdomain)" validate={[required()]} fullWidth />
    <TextInput source="eventTitle" label="Campaign Title (e.g. VIRTUAL RUN GMM SPECIAL 2020)" validate={[required()]} fullWidth />
    <SelectArrayInput label="Locale" choices={LOCALES} source="locale" validate={required()} fullWidth />
    <BooleanInput
      source="isParentCampaign"
      helperText="Enable to show a landingpage which links to the campaigns defined in 'childCampaigns"
    />
    <TextInput
      source="childCampaigns"
      helperText="Comma-separated list of campaign names (Example: 'virace, berlin, gurtegredi')"
      fullWidth
    />
    <TextInput source="iosLink" validate={[required()]} fullWidth />
    <TextInput source="androidLink" validate={[required()]} fullWidth />
    <BooleanInput source="enableLiveMap" />
    <BooleanInput source="enableCodeSignup" />
    <DateTimeInput source="startDateTime" validate={[required()]} fullWidth />
    <DateTimeInput source="gpxFrom" />
    <DateTimeInput source="gpxTo" />
    <NumberInput source="limitMargin" label="Allowed distance deviation in %" fullWidth />
    <TextInput source="mainColor" validate={[required()]} label="main color in hex" />
    <TextInput source="backgroundColor" validate={[required()]} label="background color in hex" />
    <BooleanInput source="showLivePage" />
    <BooleanInput source="enableSignup" />
    <BooleanInput source="enableUnSignup" />
    <BooleanInput source="enableGpxUpload" />
    <BooleanInput source="checkLimit" />
    <BooleanInput source="enableCountdown" />
    <BooleanInput source="enablePerformance" />
  </>
);

export const CampaignEdit = (props: any) => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label="General">
        <CommonFormPart />
      </FormTab>
      <FormTab label="Files">
        <BackendFileUpload label="Top Banner" source="topBanner" accept="image/*" {...props} />
        <BackendFileUpload label="Logo" source="logo" accept="image/*" {...props} />
        <BackendFileUpload label="Footer Banner" source="footerBanner" accept="image/*" {...props} />
        <BackendFileUpload label="Bottom Logo" source="bottomLogo" accept="image/*" {...props} />
        <BackendFileUpload label="Favicon" source="favicon" accept="image/*" {...props} />
        <BackendFileUpload label="App Screenshot" source="appScreenshot" accept="image/*" {...props} />
      </FormTab>
      <FormTab label="Text">
        <Translatable source="slogan">
          <TextInput label="Slogan" fullWidth />
        </Translatable>
        <Translatable source="textBlock1" helperText="DO NOT USE MORE THAN 250 CHARACTERS!">
          <TextInput label="Text Block 1"  fullWidth />
        </Translatable>
        <Translatable source="textBlock2">
          <TextInput label="Text Block 2" fullWidth />
        </Translatable>
        <Translatable source="textBlock3">
          <TextInput label="Text Block 3" fullWidth />
        </Translatable>
        <Translatable source="textBlock1LoggedIn">
          <TextInput label="Text Block 1 Logged In" fullWidth />
        </Translatable>
        <Translatable source="privacyPolicy">
          <TextInput label="Privacy Policy" fullWidth />
        </Translatable>
        <Translatable source="impressum">
          <TextInput label="Impressum" fullWidth />
        </Translatable>
        <TextInput source="footerText" />
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const CampaignList = (props: any) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="eventTitle" />
      <DateField source="createdDate" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export const CampaignShow = (props: any) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Campaigns">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="eventTitle" />
      </Tab>
      <Tab label="Rooms">
        <ReferenceManyField perPage={10} label="Rooms" reference="room" target="campaignId">
          <Datagrid>
            <TextField source="id" />
            <TextField source="event_title" />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const CampaignCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <CommonFormPart />
    </SimpleForm>
  </Create>
);
