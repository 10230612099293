import React from 'react';
import { FileInput, FileField, useRefresh } from 'react-admin';
import { APIService } from './api.service';
import { useToasts } from 'react-toast-notifications';

interface Props {
  id?: number;
  source: string;
  resource: string;
  refreshOnSuccess?: boolean;
  label: string;
  accept: string;
}

const BackendFileUpload = (props: Props) => {
  const { addToast } = useToasts();
  const refresh = useRefresh();

  console.log(props);

  const onDropCallback = async (files: File[]) => {
    files.forEach(async (file) => {
      const result = await APIService.uploadFile(file, props.resource, props.source, props.id);

      if (result) {
        if (props.refreshOnSuccess) {
          refresh();
        }
        addToast(result.url ? 'File ' + result.url + ' has been uploaded!' : result.message, {
          appearance: 'success',
          autoDismiss: true,
        });
      } else {
        addToast('Something went wrong!', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    });
  };

  const onRemoveCallback = async () => {
    // Upload on resource index --> no removal
    if (props.id === undefined) {
      return;
    }

    await APIService.deleteFile(props.id, props.resource, props.source);
    addToast('File has been deleted!', {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  return (
    <FileInput
      source={props.source}
      label={props.label}
      accept={props.accept}
      options={{ onDrop: onDropCallback, onRemove: onRemoveCallback }}
    >
      <FileField source="url" title="url" />
    </FileInput>
  );
};

export default BackendFileUpload;
