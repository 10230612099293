import React, { FunctionComponent } from 'react';
import { TextField } from 'mui-rff';
import PropTypes from 'prop-types';
import { useLogin, useNotify, useSafeSetState, useTranslate } from 'ra-core';
import { Field, Form } from 'react-final-form';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface Props {
  redirectTo?: string;
}

interface FormData {
  username: string;
  password: string;
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'RaLoginForm' }
);

const Input = ({ meta: { touched, error }, input: inputProps, ...props }: { meta: { touched: any; error: any }; input: any }) => (
  <TextField error={!!(touched && error)} helperText={touched && error} {...inputProps} {...props} fullWidth />
);

const LoginForm: FunctionComponent<Props> = (props) => {
  const { redirectTo } = props;
  const [loading, setLoading] = useSafeSetState(false);
  const login = useLogin();
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles(props);

  const validate = (values: FormData) => {
    const errors: { username: string | undefined; password: string | undefined } = { username: undefined, password: undefined };

    if (!values.username) {
      errors.username = translate('ra.validation.required');
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required');
    }
    return errors;
  };

  const onSubmit = (values: any) => {
    setLoading(true);
    login(values, redirectTo)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notify(
          typeof error === 'string' ? error : typeof error === 'undefined' || !error.message ? 'ra.auth.sign_in_error' : error.message,
          'warning'
        );
      });
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.form}>
            <div className={classes.input}>
              <Field
                autoFocus
                id="username"
                name="username"
                component={Input}
                label={translate('ra.auth.username')}
                disabled={loading}
                autoComplete="current-username"
              />
            </div>
            <div className={classes.input}>
              <Field
                id="password"
                name="password"
                component={Input}
                label={translate('ra.auth.password')}
                type="password"
                disabled={loading}
                autoComplete="current-password"
              />
            </div>
            <div className={classes.input}>
              <Field
                id="tenant"
                name="tenant"
                component={Input}
                label="Organization"
                disabled={loading}
                value="virace"
                autoComplete="current-tenant"
              />
            </div>
          </div>
          <CardActions>
            <Button variant="contained" type="submit" color="primary" disabled={false} className={classes.button}>
              {loading && <CircularProgress className={classes.icon} size={18} thickness={2} />}
              {translate('ra.auth.sign_in')}
            </Button>
          </CardActions>
        </form>
      )}
    />
  );
};

LoginForm.propTypes = {
  redirectTo: PropTypes.string,
};

export default LoginForm;
