import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport';

const codesExporter = (codes: any) => {
  const codesToExport = codes.map((code: any) => {
    return {
      id: code.id,
      roomId: code.roomId,
      userId: code.userId,
      code: code.code,
      reusable: code.reusable,
      team: code.team,
      createdAt: code.createdAt,
      updatedAt: code.updatedAt,
    };
  });

  jsonExport(codesToExport, (err: any, csv: any) => {
    downloadCSV(csv, 'codes'); // download as 'users.csv` file
  });
};

export default codesExporter;
