import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  Edit,
  ShowButton,
  EditButton,
  Filter,
  SimpleShowLayout,
  Show,
  DateField,
  NumberField,
} from 'react-admin';

const ProgressFilter = (props: any) => <Filter {...props}></Filter>;

export const ProgresssList = (props: any) => (
  <List {...props} filters={<ProgressFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <NumberField source="room.id" />
      <TextField source="progress" />
      <DateField source="createdDate" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export const ProgressCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm redirect="show"></SimpleForm>
  </Create>
);

const ProgressEditTitle = ({ record }: any) => <span>{`${record.firstname} ${record.lastname}`}</span>;

export const ProgressEdit = (props: any) => (
  <Edit {...props} title={<ProgressEditTitle record />}>
    <SimpleForm redirect="list"></SimpleForm>
  </Edit>
);

export const ProgressShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout></SimpleShowLayout>
  </Show>
);
